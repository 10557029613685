import * as React from "react";
import styled from "styled-components";
import BaseShape from "../types/BaseShape";

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isDefault?: boolean;
  image: string;
  label: string;
  shape: BaseShape;
}

const StyledRadio = styled.input`
  appearance: none;
  background-position: center center;
  background-repeat: no-repeat;
  border: 0 none;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px gray;
  cursor: pointer;
  height: 113px;
  width: 100%;
  transition: 0.2s background-color, 0.2s box-shadow;

  &:focus {
    outline: 0;
  }

  &:checked {
    background-color: #ebfcfb;
    box-shadow: inset 0 0 0 2px var(--color-primary);
  }
`;

const Container = styled.label`
  flex-grow: 2;
  margin-right: 16px;
  text-align: center;

  span {
    display: inline-block;
    margin-top: 16px;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Radio = React.forwardRef(
  (
    { image, isDefault = false, label, shape, ...props }: RadioProps,
    ref: any
  ) => {
    return (
      <Container htmlFor={`shape-${shape}`}>
        <StyledRadio
          defaultChecked={isDefault}
          type="radio"
          id={`shape-${shape}`}
          name="shape"
          ref={ref}
          style={{ backgroundImage: `url(${image})` }}
          value={shape}
          {...props}
        />
        <span>{label}</span>
      </Container>
    );
  }
);

export default Radio;
