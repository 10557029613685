/// <reference path="../index.d.ts" />

import * as React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Button from "../atoms/Button";
import ConfirmSaveModal from "./ConfirmSaveModal";
import caretRight from "../img/caret-right.svg";
import pencil from "../img/pencil.svg";
import exclamation from "../img/exclamation-circle.svg";
import type { AppProps, SetAppProps } from "../types/AppProps";
import { BREAKPOINT_S, LAYOUT_NAME_MAX_LENGTH, Z_INDICES } from "../constants";
import { renameLayout } from "../utils/store";
import saveLayoutToApi from "../utils/saveLayoutToApi";
import Flow from "../types/Flow";
import KEYS_DOWN, { keyUp } from "../utils/keysDown";
import { useXProps } from "../hooks/useXprops";

export const topnavHeight = 100;
const h3FontSize = 24;

const HeaderTitle = styled.h3`
  display: inline-block;
  font-size: ${h3FontSize}px;
  margin: 0 16px 0 0;
  vertical-align: middle;
`;

const StyledHeaderWrapper = styled.div`
  background: #fff;
  box-shadow: 0 3px 17px rgba(0, 0, 0, 0.07);
  z-index: 1;
`;

const StyledHeader = styled.header`
  align-items: center;
  display: flex;
  height: ${topnavHeight}px;
  justify-content: space-between;
  left: 0;
  padding: 24px 32px;
  top: 0;
  width: 100%;
  z-index: ${Z_INDICES.TOPNAV};

  @media screen and (max-width: ${BREAKPOINT_S}px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const BackButton = styled.a`
  color: inherit;
  font-size: 12px;
  font-weight: bold;
  margin-right: 40px;
  text-decoration: none;
  vertical-align: middle;

  img {
    margin-right: 8px;
  }
`;

const InputPrompt = styled.div`
  border-top: 1px dashed var(--color-primary);
  color: var(--color-text-secondary);
  font-size: 12px;
  left: 0;
  opacity: 0;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  transition: 0.2s opacity;
  width: 100%;

  input:focus ~ & {
    opacity: 1;
  }
`;

/**
 * The Right Stuff = the stuff on the right
 * (naming things is hard)
 */
const TheRightStuff = styled.div`
  flex-shrink: 0;
`;

const SpotCount = styled.h3`
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  margin: 0 16px 0 0;
  text-transform: uppercase;
  vertical-align: middle;
`;

const TheLeftStuff = styled.div`
  max-width: calc(100% - 270px);
`;

const Breadcrumbs = styled.div<{ breadcrumbsHidden: boolean }>`
  color: var(--color-text-secondary);
  height: ${props => (props.breadcrumbsHidden ? 0 : 18)}px;
  margin-bottom: 10px;
  opacity: ${props => (props.breadcrumbsHidden ? 0 : 1)};
  transition: 0.2s height, 0.2s opacity;
`;

/**
 * The magic number in the `max-width` is the width of the container
 * minus the width of <TheRightStuff> (give or take)
 */
const Name = styled(HeaderTitle)`
  align-items: center;
  display: flex;

  input {
    appearance: none;
    border: 0 none;
    font-family: inherit;
    font-size: inherit;
    max-width: 100%;
    padding: 0;
    text-overflow: ellipsis;

    &:focus {
      min-width: 270px;
      outline: 0;

      & ~ img {
        display: none;
      }
    }
  }

  img {
    cursor: pointer;
    height: 18px;
    width: 18px;
  }
`;

const Banner = styled.div`
  align-items: center;
  background-color: var(--color-UIwarning-extralight);
  color: var(--color-UIwarning-dark);
  display: flex;
  justify-items: flex-start;
`;

const Icon = styled.img`
  height: 16px;
  margin-left: 2rem;
  margin-right: 0.5rem;
`;

const StyledText = styled.p`
  font-size: 14px;
  margin: 8px 0;
`;

/**
 * Renders an editable input (with the appearance of an h3)
 * that dynamically updates its width based on its value.
 */
const DynamicWidthInput = React.forwardRef(
  (props: React.InputHTMLAttributes<HTMLInputElement>, ref: any) => {
    const [width, setWidth] = React.useState(100);
    React.useEffect(() => {
      const value = (props.value || props).toString();
      const span = document.createElement("span");
      span.innerHTML = value;
      span.style.fontSize = h3FontSize + "px";
      document.body.appendChild(span);
      const theWidth = span.getBoundingClientRect().width;
      document.body.removeChild(span);
      setWidth(Math.ceil(theWidth) + 10);
    }, [props.value]);
    return <input ref={ref} maxLength={50} {...props} style={{ width }} />;
  }
);

export default (
  props: AppProps &
    SetAppProps & {
      classroom: string;
      location: string;
    }
) => {
  const { classroom, flow, location, name, setName, spotCount, spots } = props;
  const [breadcrumbsHidden, setBreadcrumbsHidden] = React.useState(false);
  const [initialName, setInitialName] = React.useState(name);
  const nameRef = React.useRef<HTMLInputElement>();
  const [ loading, setLoading ] = React.useState(false);

  /**
   * Modal state and action
   */
  const [isModalShowing, setIsModalShowing] = React.useState<boolean>(false);
  const toggleModal = () => {
    setIsModalShowing(!isModalShowing)
  }

  /**
   * Get isEdit mode from query params
   */
  const { search } = useLocation();
  const isEdit = search?.split('=')[1] === 'edit';

  const { onSaveLayout } = useXProps();

  const saveName = () => {
    const theName = nameRef.current.value;
    if (nameRef.current.value.length === 0 || nameRef.current.value.length > 50)
      return;
    setName(theName);
    renameLayout(name, theName);

    // update URL with the new name in case the user tries to refresh
    window.history.pushState(
      {},
      "",
      window.location.origin + "/layout/" + theName
    );
  };

  const saveLayout = async () => {
    setLoading(true);
    await saveLayoutToApi({ ...props, isEdit, onSaveLayout });
    setLoading(false);
  };

  React.useEffect(() => {
    setInitialName(name);
  }, [name]);

  return (
    <>
      <StyledHeaderWrapper>
        <StyledHeader data-testid="TopNav">
          <TheLeftStuff>
            {classroom && (
              <Breadcrumbs breadcrumbsHidden={breadcrumbsHidden}>
                {location}
                {location && (
                  <img
                    src={caretRight}
                    alt=""
                    style={{ margin: "0 8px", top: 2 }}
                  />
                )}
                {classroom}
              </Breadcrumbs>
            )}
            <Name>
              {!isEdit && flow !== Flow.BEGIN && flow !== Flow.NAME 
                ? (
                  <>
                    <DynamicWidthInput
                      disabled={flow !== null}
                      maxLength={LAYOUT_NAME_MAX_LENGTH}
                      onChange={e => setInitialName(e.currentTarget.value)}
                      onFocus={() => setBreadcrumbsHidden(true)}
                      onKeyDown={e => {
                        e.stopPropagation();
                      }}
                      onKeyUp={e => {
                        e.stopPropagation();
                        if (e.key === "Enter") {
                          saveName();
                          nameRef.current.blur();
                        }
                      }}
                      onBlur={() => {
                        setBreadcrumbsHidden(false);
                        setInitialName(name);
                        KEYS_DOWN.forEach(keyUp)
                      }}
                      ref={nameRef}
                      value={initialName}
                    />{" "}
                    {nameRef.current &&
                    (nameRef.current.value.length === 0 ||
                      nameRef.current.value.length > LAYOUT_NAME_MAX_LENGTH) ? (
                      <InputPrompt style={{ opacity: 1 }}>
                        Layout name must be between 1-{LAYOUT_NAME_MAX_LENGTH}{" "}
                        characters
                      </InputPrompt>
                    ) : (
                      <InputPrompt>ENTER to save</InputPrompt>
                    )}
                    <img
                      onClick={() => nameRef.current.focus()}
                      src={pencil}
                      alt=""
                    />
                  </>
                )
                : name
            }
            </Name>
          </TheLeftStuff>
          <TheRightStuff>
            {spotCount !== null && (
              <SpotCount>
                {spotCount} Spot{spotCount !== 1 && "s"}
              </SpotCount>
            )}
            <Button
              disabled={
                spots.length === 0 ||
                nameRef.current?.value.length === 0 ||
                nameRef.current?.value.length > 50 ||
                loading === true
              }
              onClick={async () => {
                !isEdit 
                  ? await saveLayout()
                  : toggleModal();
              }}
            >
              { loading ? 'Saving...' : 'Save and Close' }
            </Button>
          </TheRightStuff>
        </StyledHeader>
        {
          isEdit && (
            <Banner>
              <Icon src={exclamation} />
              <StyledText>
                Editing Options are limited for pre-existing layouts. <strong>For more editing options, please duplicate the layout.</strong>
              </StyledText>
            </Banner>
          )
        }
      </StyledHeaderWrapper>
      <ConfirmSaveModal 
        isLoading={loading}
        isModalShowing={isModalShowing} 
        modalAction={async (e) => {
          e.preventDefault();
          await saveLayout();
          toggleModal();
        }}
        toggleModal={toggleModal}
        />
    </>
  );
};
