import type { AppProps, SetAppProps } from "../types/AppProps";
import touchesToCursor from "../utils/touchesToCursor";
import eventWithinSpot from "../utils/eventWithinSpot";
import abstractMove from "./abstractMove";

export default function onTouchMove(
  props: AppProps & SetAppProps & { trash: React.RefObject<HTMLButtonElement> }
) {
  const {
    setCursor,
    setCursorSpot,
    setIsNetting,
    setIsPanning,
    setTouchCount,
  } = props;

  return function onTouchMove(e: React.TouchEvent<HTMLElement>) {
    const cursor = touchesToCursor(e.touches);

    // TODO: zoom if pinching
    // const zoomDelta = pinchToZoom(e.touches, e.changedTouches);
    // setZoom(clampZoom(zoom + zoomDelta));

    setCursor(cursor);
    setTouchCount(e.touches.length);

    const maybeSpotId = eventWithinSpot(e);
    setCursorSpot(maybeSpotId);

    if (e.touches.length === 2) {
      setIsNetting(false);
      setIsPanning(true);
      return;
    }

    abstractMove(props);
  };
}
