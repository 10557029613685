/// <reference path="../index.d.ts" />

import * as React from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import styled from "styled-components";
import OnboardingModal from "./OnboardingModal";
import GlobalStyle from "../styles/globalStyles";
import Flow from "../types/Flow";
import BaseShape from "../types/BaseShape";
import TopNav from "./TopNav";
import Layout from "./Layout";
import Clone from "./Clone";
import Edit from "./Edit";
import Spot from "../types/Spot";
import SidePanel from "./SidePanel";
import Point from "../types/Point";
import Net from "../atoms/Net";
import concatUniq from "../utils/concatUniq";
import Toast from "../atoms/Toast";
import type { AppProps, SetAppProps } from "../types/AppProps";
import { getClassroomID, getLayoutData, saveLayoutData } from "../utils/store";
import onMouseMove from "../listeners/onMouseMove";
import onMouseUp from "../listeners/onMouseUp";
import onResize from "../listeners/onResize";
import onTouchEnd from "../listeners/onTouchEnd";
import onTouchMove from "../listeners/onTouchMove";
import log from "../utils/log";
import withPortal from "../utils/withPortal";
import trashSelectedSpots from "../utils/trashSelectedSpots";
import Debug from "../atoms/Debug";
import Fill, { LargeScreenFill } from "../atoms/Fill";
import { getSpotById } from "../utils/getSpotById";
import SpotType from "../types/SpotType";
import withAuth from "../utils/withAuth";
import urlParser from "../utils/urlParser";
import { getSpotTypes, isSandbox, getClassroom, getLocation } from "../auth";
import KEYS_DOWN, { keyDown, keyUp } from "../utils/keysDown";
import onMouseDown from "../listeners/onMouseDown";
import onTouchStart from "../listeners/onTouchStart";
import ToastStyle from "../types/ToastStyle";
import { useXProps } from "../hooks/useXprops";
import MobileStopSign from "../atoms/MobileStopSign";

const LayoutWrapper = styled(Fill)`
  user-select: none;
`;

const App = ({
  classroomId = "",
  search = window.location.search,
  path = "",
  redirectUri,
}: {
  classroomId?: string;
  search?: string;
  path?: string;
  redirectUri?: string;
}) => {
  const { initialRoute } = useXProps();
  /**
   * If the `urlParser` returns a valid `name`, assume that the user wants
   * to load a layout they worked on previously. However, if there's no saved
   * data for that name, redirect to start a new layout
   */
  const [name, setName] = React.useState<string>(urlParser.getLayout(path));
  const [redirect, setRedirect] = React.useState<string>(redirectUri || initialRoute);

  /**
   * Check query params for edit mode
   */
  const isEdit = search?.split('=')[1] === 'edit';

  React.useEffect(() => {
    if (classroomId !== "" && !redirect || redirect.includes('classroom')) {
      setRedirect("/new");
    } else if (name !== "" && getLayoutData(name) === null) {
      setName("");
      setRedirect("/new");
    }

    // not cute, making me do this double ajax call
    // but we have to get the location and classroom name somehow!
    if (getClassroomID()) {
      getClassroom(getClassroomID()).then(res => {
        setClassroom(res.attributes.name);
        getLocation(res.relationships.location.data.id).then(res => {
          setLocation(res.attributes.name);
        });
      });
    }
  }, [initialRoute]);

  /**
   * The step of the onboarding flow the user is currently in,
   * as well as meta info about the current layout.
   */
  const [flow, setFlow] = React.useState<Flow>(
    getLayoutData(name) === null ? Flow.BEGIN : null
  );
  const [shape, setShape] = React.useState<BaseShape>(BaseShape.SQUARE);
  const [spotCount, setSpotCount] = React.useState<number>(null);

  /**
   * The x/y coordinates of:
   * - `cursor`: Where the user started clicking/dragging
   * - `offsetCursor`: The cursor's current location (together, the
   *    bounding box for nets or the distance that spot/s are being dragged)
   */
  const [cursorDown, setCursorDown] = React.useState<Point>(null);
  const [cursor, setCursor] = React.useState<Point>(null);
  const [touchCount, setTouchCount] = React.useState(0);

  /**
   * Different possible user interaction states.
   */
  const [isAddingSpot, setIsAddingSpot] = React.useState<SpotType>(null);
  const [isDragging, setIsDragging] = React.useState(false);
  const [isNetting, setIsNetting] = React.useState(false);
  const [isPanning, setIsPanning] = React.useState(false);
  const [isValidDraggingLocation, setIsValidDraggingLocation] = React.useState(
    true
  );

  /**
   * For simplicity's sake, we just store spots in an array.
   * Since they are cloned often, we can't check for inclusion
   * by (ex.) `spots.includes(spot)` -- use utils/doSpotsContainSpot`.
   * Also, the helper function utils/concatUniq is useful for making
   * sure none of these contain duplicate spots.
   *
   * `singleSpot` is the spot that is being dragged without being selected.
   * It can still be trashed or moved but will remain unselected after being moved.
   */
  const [spots, setSpots] = React.useState<Spot[]>([]);
  const [netSelected, setNetSelected] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [singleSpot, setSingleSpot] = React.useState<string>(null);
  const [cursorSpot, setCursorSpot] = React.useState<string>(null);

  const [spotTypes, setSpotTypes] = React.useState<SpotType[]>([
    {
      id: "1",
      name: "Spot",
      priority: "primary",
    },
  ]);

  /**
   * `toast` is a message that pops up as a notification to the user.
   * `toastDuration` is how long it should display before fading out.
   * `toastCounter` is a helper function (lower-level components should not need
   * to call it) so that we can still show the notification even if the message
   * has not changed.
   */
  const [toast, setToast] = React.useState<string | (() => JSX.Element)>("");
  const [toastCounter, setToastCounter] = React.useState(0);
  const [toastDuration, setToastDuration] = React.useState(2);
  const [toastStyle, setToastStyle] = React.useState<ToastStyle>(
    ToastStyle.SUCCESS
  );
  const [toastCallback, setToastCallback] = React.useState<() => void>();

  const [classroom, setClassroom] = React.useState<string>(null);
  const [location, setLocation] = React.useState<string>(null);

  const [zoom, setZoom] = React.useState(getLayoutData(name)?.zoom || 1);
  const [origin, setOrigin] = React.useState<Point>(
    getLayoutData(name)?.origin || { x: 0, y: 0 }
  );

  const layoutRef = React.useRef<HTMLDivElement>();
  const trashRef = React.useRef<HTMLButtonElement>();

  /**
   * Bundle all our app-level state variables into one object to more
   * easily pass it to child components.
   */
  const appProps: AppProps = {
    flow,
    name,
    shape,
    spotCount,
    cursor,
    cursorDown,
    cursorSpot,
    isAddingSpot,
    isDragging,
    isEdit,
    isNetting,
    isPanning,
    isValidDraggingLocation,
    keysDown: KEYS_DOWN,
    origin,
    spots,
    netSelected,
    redirect,
    selected,
    singleSpot,
    spotTypes,
    toast,
    toastDuration,
    toastStyle,
    touchCount,
    zoom,
  };

  /**
   * Wrapper functions to keep data in sync with localstorage.
   * Acts like `setWhatever` so lower-level components
   * don't have to worry about this additional logic.
   */
  const wrappedSetSpots = (spots: Spot[] = []) => {
    setSpots(spots);
    setSpotCount(spots.length);
    saveLayoutData(name, { spots });
  };

  const wrappedSetZoom = (zoom: number = 1) => {
    setZoom(zoom);
    saveLayoutData(name, { zoom });
  };

  const wrappedSetOrigin = (origin: Point = { x: 0, y: 0 }) => {
    setOrigin(origin);
    saveLayoutData(name, { origin });
  };

  const wrappedSetToast = (
    str: typeof toast,
    type: ToastStyle = ToastStyle.SUCCESS,
    callback?: typeof toastCallback, 
  ) => {
    setToast(str);
    setToastStyle(type);
    if (callback) setToastCallback(callback);
    setToastCounter(toastCounter + 1);
  };

  /**
   * Like all the state variables themselves, bundle all the `setWhatever`
   * functions into one object so they can easily be passed around.
   */
  const setAppProps: SetAppProps = {
    setCursorDown,
    setCursor,
    setCursorSpot,
    setFlow,
    setIsAddingSpot,
    setIsDragging,
    setIsNetting,
    setIsPanning,
    setIsValidDraggingLocation,
    setName,
    setNetSelected,
    setOrigin: wrappedSetOrigin,
    setRedirect,
    setShape,
    setSpotCount,
    setSpots: wrappedSetSpots,
    setSelected,
    setSingleSpot,
    setToast: wrappedSetToast,
    setToastDuration,
    setToastStyle,
    setTouchCount,
    setZoom: wrappedSetZoom,
  };

  const combinedSelected = concatUniq(selected, netSelected);

  /**
   * Sets `cursorDown` to null and resets user interaction
   * flags (`isDragging`, `isNetting`)
   */
  const reset = (hard: boolean = true) => {
    setCursor(null);
    setCursorDown(null);
    setIsAddingSpot(null);
    setIsDragging(false);
    setIsNetting(false);
    setIsPanning(false);
    setIsValidDraggingLocation(true);
    setSingleSpot(null);
    setTouchCount(0);

    /**
     * By default, we  want a hard reset, which takes the spots
     * that were just in `netSelected` and adds them to `selected`
     */
    if (hard) {
      setSelected(combinedSelected);
      setNetSelected([]);
    }
  };

  const globalOnMouseDown = onMouseDown({ ...appProps, ...setAppProps });
  const globalOnMouseMove = onMouseMove({
    ...appProps,
    ...setAppProps,
    trash: trashRef,
  });
  const globalOnMouseUp = onMouseUp({
    ...appProps,
    ...setAppProps,
    trash: trashRef,
    reset,
  });
  const globalOnTouchStart = onTouchStart({ ...appProps, ...setAppProps });
  const globalOnTouchMove = onTouchMove({
    ...appProps,
    ...setAppProps,
    trash: trashRef,
  });
  const globalOnTouchEnd = onTouchEnd({
    ...appProps,
    ...setAppProps,
    trash: trashRef,
    reset,
  });

  React.useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  /**
   * Get spot types from the API
   */
  React.useEffect(() => {
    if (isSandbox()) return;
    (async () => {
      const types = await getSpotTypes();
      log("Got spot types", types);
      if (!types) return;
      const spotTypes = types.map(type => {
        return {
          id: type.id,
          name: type.attributes.name,
          priority: type.attributes.priority,
        };
      }).filter((type) => type.name !== 'Default Migrated Spot Type');
      setSpotTypes(spotTypes);
    })();
  }, []);

  /**
   * Add global keyboard shortcuts
   */
  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (flow !== null) return;
      keyDown(e.key);
    };
    const onKeyUp = (e: KeyboardEvent) => {
      if (flow !== null) return;
      keyUp(e.key);
      switch (e.key) {
        case "Backspace":
          if (combinedSelected.length > 0 && !isEdit) {
            setNetSelected([]);
            setSelected([]);
            trashSelectedSpots({ ...appProps, ...setAppProps });
          }
          return;
        case "Escape":
          log(
            `Pressed escape: Reset and clear selected (${combinedSelected
              .map(id => getSpotById(spots, id).name)
              .join(", ")})`
          );
          reset();
          setSelected([]);
          return;
        case "ArrowRight":
          return wrappedSetOrigin({
            x: origin.x - 100,
            y: origin.y,
          });
        case "ArrowLeft":
          return wrappedSetOrigin({
            x: origin.x + 100,
            y: origin.y,
          });
        case "ArrowUp":
          return wrappedSetOrigin({
            x: origin.x,
            y: origin.y - 100,
          });
        case "ArrowDown":
          return wrappedSetOrigin({
            x: origin.x,
            y: origin.y + 100,
          });
        default:
          return;
      }
    };
    window.addEventListener("keypress", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    return () => {
      window.removeEventListener("keypress", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    };
  }, [flow, combinedSelected]);

  return (
    <Router>
      {redirect && <Redirect to={redirect} />}
      <GlobalStyle />
      <LargeScreenFill
        data-testid="App"
        onMouseDown={globalOnMouseDown}
        onMouseUp={globalOnMouseUp}
        onMouseMove={globalOnMouseMove}
        onTouchMove={globalOnTouchMove}
        onTouchEnd={globalOnTouchEnd}
        onTouchStart={globalOnTouchStart}
      >
        <TopNav
          {...appProps}
          {...setAppProps}
          classroom={classroom}
          location={location}
        />
        {
          !redirect && (
            <Route path="/" exact>
              <Redirect to="/new" /> 
            </Route>
          )
        }
        {!redirect && (
          <Route path="/auth">
            <Redirect to="/new" />
          </Route>
        )}
        <Route path="/new" exact>
          <OnboardingModal {...appProps} {...setAppProps} />
        </Route>
        <Route path="/clone/:layout">
          <Clone {...appProps} {...setAppProps} />
        </Route>
        <Route path="/edit/:layout">
          <Edit {...appProps} {...setAppProps} />
        </Route>
        <Route path="/layout/:layout">
          <Fill>
            <LayoutWrapper ref={layoutRef}>
              <Layout
                {...appProps}
                {...setAppProps}
                layout={layoutRef}
                reset={reset}
                selected={concatUniq(selected, netSelected)}
                trash={trashRef}
              />
              {isNetting && <Net cursorDown={cursorDown} cursor={cursor} />}
            </LayoutWrapper>
            <SidePanel
              {...appProps}
              {...setAppProps}
              layout={layoutRef}
              trash={trashRef}
            />
          </Fill>
        </Route>
        {toast && (
          <Toast
            callback={toastCallback}
            counter={toastCounter}
            duration={toastDuration}
            message={toast}
            type={toastStyle}
            isEdit={isEdit}
          />
        )}
        <Debug search={search} {...appProps} {...setAppProps} />
      </LargeScreenFill>
      <MobileStopSign />
    </Router>
  );
};

export default withPortal(withAuth(App), "app");
