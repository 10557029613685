import * as React from "react";
import styled, { css } from "styled-components";
import secondary from "../img/secondary.svg";
import SideTooltip from "./SideTooltip";
import SpotTooltip from "./SpotTooltip";

const SecondaryTooltipWrapper = styled.div<{ type: "side" | "spot" }>`
  display: none;
  left: ${props => (props.type === "side" ? -80 : 0)}px;
  z-index: 9999;
`;

const SecondaryIconWrapper = styled.span<{ type: "side" | "spot" }>`
  &:after {
    background: #fff;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.08);
    bottom: ${props => (props.type === "side" ? 24 : 9)}px;
    content: "";
    display: none;
    height: 14px;
    left: -1px;
    position: absolute;
    transform: rotate(45deg);
    width: 14px;
    z-index: 10000;
  }
`;

const SecondaryWrapper = styled.span<{ type: "side" | "spot" }>`
  &:hover ${SecondaryTooltipWrapper}, &:hover ${SecondaryIconWrapper}:after {
    display: block;
  }

  ${props =>
    props.type === "spot" &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
    `}
`;

const SecondaryIconInline = styled.img``;

SecondaryIconInline.defaultProps = {
  src: secondary,
};

const SecondaryIconSpot = styled.img`
  left: 5px;
  pointer-events: none;
  position: absolute;
  top: 5px;
`;

SecondaryIconSpot.defaultProps = {
  src: secondary,
};

export default ({ type }: { type: "side" | "spot" }) => {
  return (
    <SecondaryWrapper type={type}>
      <SecondaryIconWrapper type={type}>
        {type === "side" ? <SecondaryIconInline /> : <SecondaryIconSpot />}
      </SecondaryIconWrapper>
      <SecondaryTooltipWrapper type={type}>
        {type === "side" ? <SideTooltip /> : <SpotTooltip />}
      </SecondaryTooltipWrapper>
    </SecondaryWrapper>
  );
};
