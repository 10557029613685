export const SPOT_COUNT_MAX = 300;
export const LAYOUT_NAME_MAX_LENGTH = 50;

export const BREAKPOINT_M = 1200;
export const BREAKPOINT_S = 900;

export enum Z_INDICES {
  SPOT_SHADOW = 2,
  SPOT = 3,
  SPOT_SELECTED = 4,
  TOPNAV = 10,
  ONBOARDING_MODAL_SHADOW = 20,
  ONBOARDING_MODAL = 21,
}
