import * as React from "react";
import Point from "../types/Point";
import styled from "styled-components";
import netBoundingBox from "../utils/netBoundingBox";

const StyledNet = styled.div`
  background: rgba(0, 206, 199, 0.1);
  border: 1px solid var(--color-primary);
  cursor: auto;
  position: absolute;
`;

const Net = ({ cursorDown, cursor }: { cursorDown: Point; cursor: Point }) => {
  if (cursorDown === null || cursor === null) return null;
  const box = netBoundingBox(cursorDown, cursor);
  const left = box.x;
  const top = box.y;
  const { width, height } = box;
  return <StyledNet style={{ height, left, top, width }} />;
};

export default Net;
