import * as React from "react";
import styled, { css } from "styled-components";
import { topnavHeight } from "../components/TopNav";
import { sidePanelWidth, sidePanelWidthMed } from "../components/SidePanel";
import { BREAKPOINT_M } from "../constants";
import ToastStyle from "../types/ToastStyle";

const StyledToast = styled.aside<{ fade: boolean; type: ToastStyle; isEdit: boolean; }>`
  background-color: var(
    ${props =>
      props.type === ToastStyle.SUCCESS
        ? "--color-primary-extralight"
        : "--color-UInegative-extralight"}
  );
  border: 1px solid
    var(
      ${props =>
        props.type === ToastStyle.SUCCESS
          ? "--color-primary"
          : "--color-UInegative"}
    );
  border-radius: 6px;
  color: var(
    ${props =>
      props.type === ToastStyle.SUCCESS
        ? "--color-primary"
        : "--color-UInegative"}
  );
  font-weight: bold;
  left: calc((100% - ${props => props.isEdit ? 0 : sidePanelWidth}px) / 2);
  padding: 16px;
  position: absolute;
  text-align: center;
  top: ${topnavHeight + 24}px;
  transform: translateX(-50%);
  transition: 0.3s opacity;
  user-select: none;
  width: 500px;
  z-index: 2;

  @media screen and (max-width: ${BREAKPOINT_M}px) {
    left: calc((100vw - ${props => props.isEdit ? 0 : sidePanelWidthMed}px) / 2);
  }

  ${props =>
    props.fade &&
    css`
      opacity: 0;
      pointer-events: none;
    `}

  @media screen and (max-width: ${BREAKPOINT_M}px) {
    left: calc((100% - ${props => props.isEdit ? 0 : sidePanelWidthMed}px) / 2);
    max-width: calc((100% - ${props => props.isEdit ? 0 : sidePanelWidthMed + 24}px));
  }

  button {
    -webkit-appearance: none;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    font: inherit;
    padding: 0;
    text-decoration: underline;
  }
`;

const Toast = ({
  callback = () => {},
  counter,
  duration,
  message,
  type,
  isEdit
}: {
  callback: () => void;
  counter: number;
  duration: number;
  message: string;
  type: ToastStyle;
  isEdit: boolean;
}) => {
  const [fade, setFade] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState(-1);

  React.useEffect(() => {
    setFade(false);
    window.clearTimeout(timeoutId);
    const id = window.setTimeout(() => {
      setFade(true);
      callback();
    }, duration * 1000);
    setTimeoutId(id);
  }, [counter, duration, message]);

  return (
    <StyledToast
      fade={fade}
      onClick={() => setFade(true)}
      type={type}
      isEdit={isEdit}
    >
      {message}
    </StyledToast>
  );
};

export default React.memo(Toast);
