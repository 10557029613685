/// <reference path="../index.d.ts" />

import * as React from "react";
import styled from "styled-components";
import Spot from "../types/Spot";
import type { AppProps, SetAppProps, SetFunction } from "../types/AppProps";
import StyledSpot from "./StyledSpot";
import SpotNodeType from "./SpotNodeType";
import log from "../utils/log";
import isSpotSingular from "../utils/isSpotSingular";
import { getSpotById, getSpotsById } from "../utils/getSpotById";
import SecondaryIcon from "./SecondaryIcon";
import { cloneDeep } from "lodash";

/**
 * <SelectableArea> is the actual element that lets the user interact
 * with the spot. Making this the first element within the <StyledSpot>
 * container makes click handling simpler, since clicks/touches on subsequent elements
 * won't bubble up (as opposed to if <StyledSpot> received click events).
 */
const SelectableArea = styled.div<{ isNetting: boolean }>`
  appearance: none;
  background: transparent;
  border: 0 none;
  cursor: ${props => (props.isNetting ? "auto" : "move")};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:focus {
    outline: 0;
  }
`;

const dynamicFontSize = (strLength: number): number => {
  if (strLength < 3) return 18;
  if (strLength < 5) return 15;
  if (strLength < 7) return 13;
  if (strLength < 9) return 11;
  return 9.5;
};

export const StyledInput = styled.input<{
  isNetting?: boolean;
}>`
  appearance: none;
  background: transparent;
  border: 0 none;
  border-bottom: 1px solid transparent;
  color: var(--color-text);
  font-family: var(--text-primary);
  font-size: 15px;
  font-weight: bold;
  margin: 0 0 2px;
  max-width: 100%;
  padding: 0;
  pointer-events: ${props => (props.isNetting ? "none" : "auto")};
  text-align: center;
  text-overflow: ellipsis;
  width: 50px;

  &:focus {
    outline: 0;
    border-bottom: 1px solid #e4e6e9;
  }

  &:disabled {
    color: var(--color-text);
  }
`;

interface SpotNodeProps extends React.HTMLAttributes<HTMLDivElement> {
  cursorOverSpot: boolean;
  cursorOverTrash: boolean;
  interactive: boolean;
  isDragging: boolean;
  isNetting: boolean;
  isPanning: boolean;
  isValidDraggingLocation: boolean;
  netSelected: string[];
  selected: string[];
  setSelected: SetFunction<string[]>;
  setSingleSpot: SetFunction<string>;
  setSpots: SetFunction<Spot[]>;
  singleSpot: string;
  spot: Spot;
  spots: Spot[];
  trash: React.RefObject<HTMLButtonElement>;
  zoom: number;
}

const SpotNode = (props: SpotNodeProps) => {
  const {
    cursorOverTrash,
    interactive,
    isNetting,
    isDragging,
    isValidDraggingLocation,
    selected,
    setSingleSpot,
    setSpots,
    singleSpot,
    spot,
    spots,
    style,
    zoom,
  } = props;

  const isSelected = selected.includes(spot.id);
  const isSingular = isSpotSingular(spot, props);

  const ref = React.useRef<HTMLInputElement>();
  const [fontSize, setFontSize] = React.useState(
    dynamicFontSize((props.defaultValue || spot.name).toString().length)
  );

  /**
   * Apply a slight rotation if dragging:
   * - a single spot
   * - or a selected spot (when it is the only one selected)
   */
  const rotation =
    isDragging &&
    (isSingular || (selected.length === 1 && selected[0] === spot.id))
      ? 5
      : 0;

  const rename = (name: string) => {
    if (name === spot.name) return;
    const newSpot = cloneDeep(spot);
    newSpot.name = name;
    log(`Renamed spot ${spot.name} to ${newSpot.name}`);
    const newSpots = spots.map(s => (s === spot ? newSpot : s));
    setSpots(newSpots);
  };

  const validLocation = !isValidDraggingLocation
    ? !isSingular && !isSelected
    : true;

  let left = spot.x;
  let top = spot.y;

  // stack spots if they're selected and the cursor is over the trash
  if (cursorOverTrash && singleSpot !== null && (isSingular || isSelected)) {
    const stackIndex =
      selected.length > 0 ? selected.findIndex(id => id === spot.id) : 0;
    const shift = Math.min(stackIndex * 4, 40);
    const theSingleSpot = getSpotById(spots, singleSpot);
    left = theSingleSpot.x - shift;
    top = theSingleSpot.y - shift;
  }

  const transform =
    (style ? style.transform + " " : "") + `rotate(${rotation}deg)`;

  return (
    <StyledSpot
      available={spot.available}
      cursorOverTrash={cursorOverTrash}
      data-spot-id={spot.id}
      data-testid="SpotNode"
      interactive={interactive}
      invalid={!validLocation}
      isSelected={isSelected}
      isSingular={isSingular}
      ref={ref}
      shouldOffset={false}
      style={Object.assign({}, style, {
        left,
        top,
        transform,
        // TODO: update z-index on the fly? (might need to
        // when doing fancy spot trashing)
      })}
    >
      <SelectableArea isNetting={isNetting} />
      {spot.type?.priority === "secondary" && <SecondaryIcon type="spot" />}
      <StyledInput
        defaultValue={props.defaultValue || spot.name}
        disabled={isNetting || isDragging}
        onChange={e => {
          rename(e.currentTarget.value);
          const { value } = e.currentTarget;
          if (value.length < 3) return setFontSize(18);
          if (value.length < 5) return setFontSize(15);
          if (value.length < 7) return setFontSize(13);
          if (value.length < 9) return setFontSize(11);
          return setFontSize(9.5);
        }}
        onKeyDown={e => e.stopPropagation()}
        onKeyUp={e => e.stopPropagation()}
        onMouseDown={e => e.stopPropagation()}
        onMouseUp={e => e.stopPropagation()}
        onTouchStart={e => e.stopPropagation()}
        onTouchEnd={e => e.stopPropagation()}
        ref={ref}
        style={{ fontSize }}
      />
      <SpotNodeType zoom={zoom}>{spot.type.name}</SpotNodeType>
    </StyledSpot>
  );
};

/**
 * Return `false` when the component should re-render.
 */
function rerender(prevProps: SpotNodeProps, nextProps: SpotNodeProps) {
  if (nextProps.isPanning) return true;
  return ![
    "cursorOverSpot",
    "cursorOverTrash",
    "interactive",
    "isDragging",
    "isNetting",
    "netSelected",
    "selected",
    "style",
    "zoom",
  ].some(key => {
    return prevProps[key] !== nextProps[key];
  });
}

export default React.memo(SpotNode, rerender);
