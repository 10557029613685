import * as React from "react";
import queryString from "query-string";
import { AppProps, SetAppProps } from "../types/AppProps";

const Debug = ({
  search = "",
  ...props
}: { search?: string } & AppProps & SetAppProps) => {
  const params = queryString.parse(search);
  if (!params.debug) return null;

  const {
    cursor,
    cursorDown,
    isDragging,
    isNetting,
    isValidDraggingLocation,
    setOrigin,
    singleSpot,
  } = props;

  return (
    <div style={{ position: "fixed", bottom: 0, right: 0 }}>
      <button onClick={() => setOrigin({ x: 0, y: 0 })}>Recenter</button>
      <div>
        {cursor ? (
          <>
            {cursor.x}, {cursor.y}
          </>
        ) : (
          "no cursor"
        )}
      </div>
      <div>
        {cursorDown ? (
          <>
            {cursorDown.x}, {cursorDown.y}
          </>
        ) : (
          "no cursorDown"
        )}
      </div>
      <div>{singleSpot ? singleSpot : "no singleSpot"}</div>
      <div>{isNetting ? "netting" : "not netting"}</div>
      <div>{isDragging ? "dragging" : "not dragging"}</div>
      <div>{isValidDraggingLocation ? "location good" : "location bad"}</div>
    </div>
  );
};

export default Debug;
