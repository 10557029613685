import * as React from "react";
import styled from "styled-components";
import Tooltip from "./Tooltip";

const Title = styled.h5`
  border: 0 none;
  margin: 0;
  text-decoration: none;
  font-weight: bold;
  font-size: 12px;

  img {
    margin: 0 4px 0 0 !important;
    top: 1px;
  }
`;

const StyledTooltip = styled(Tooltip)`
  bottom: 16px;
  left: -52px;
  padding: 8px;
  width: 120px;
  text-align: center;
`;

const SpotTooltip = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <StyledTooltip {...props}>
      <Title>Secondary Spot</Title>
    </StyledTooltip>
  );
};

export default SpotTooltip;
