import type { AppProps, SetAppProps } from "../types/AppProps";
import eventWithinSpot from "../utils/eventWithinSpot";
import abstractMove from "./abstractMove";

export default function onMouseMove(
  props: AppProps &
    SetAppProps & {
      trash: React.RefObject<HTMLButtonElement>;
    }
) {
  const { setCursor, setCursorSpot } = props;

  return function onMouseMove(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    setCursor({ x: e.pageX, y: e.pageY });

    const maybeSpotId = eventWithinSpot(e);
    setCursorSpot(maybeSpotId);

    abstractMove(props);
  };
}
