// This is a nasty little pair of functions to handle the
// sad truth that react-router's history module deals oh-so-poorly
// with "%" (as a non-URI encoded character) in location URLs. However,
// we may want a customer to be able to name a layout (e.g.) "50% fill classroom"
// because, as of the time of this writing, there's still a global pandemic
// that's necessitating something called 'social distancing' -- even though
// some folks still want to exercise in enclosed areas and other folks want to
// let them do that and charge money for it. Anyway!
//
// In a nutshell, the below pair of functions take a string possibly containing
// a "%" character and swap it for a character combination that's unlikely to appear
// in the average person's layout name. When someone does include this combination in their
// layout name? Well... we'll cross that bridge when we come to it.
// These functions can be thought of as *pre-encoding* a string before it actually gets
// URIEncoded and *post-decoding* it after it has been URIDecoded.
//
// Sincerely,
// Scott Donaldson
// May 24, 2021

export function percentEncode(str: string): string {
  return str.replace(/%/g, "__p");
}

export function percentDecode(str: string): string {
  return str.replace(/__p/g, "%");
}
