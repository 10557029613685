import * as React from "react";
import styled from "styled-components";
import Tooltip from "./Tooltip";
import secondaryIcon from "../img/secondary.svg";

const Title = styled.h5`
  border: 0 none;
  margin: 0 0 6px !important;
  text-decoration: none;

  img {
    margin: 0 2px 0 0 !important;
    top: 1px;
  }
`;

const SideTooltip = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Tooltip {...props}>
      <Title>
        <img src={secondaryIcon} alt="" /> = Secondary Spot
      </Title>
      Secondary spots are not automatically filled from the waitlist. When only
      secondary spots are left, customers can choose to book the secondary spot
      or join the waitlist for a primary spot.
    </Tooltip>
  );
};

export default SideTooltip;
