import type { AppProps, SetAppProps } from "../types/AppProps";
import eventWithinSpot from "../utils/eventWithinSpot";
import { getSpotById } from "../utils/getSpotById";
import log from "../utils/log";
import touchesToCursor from "../utils/touchesToCursor";

export default function onTouchStart(props: AppProps & SetAppProps) {
  const { setCursor, setCursorDown, setSingleSpot, spots } = props;

  return function onTouchStart(e: React.TouchEvent<HTMLElement>) {
    const c = touchesToCursor(e.touches);
    setCursor(c);
    setCursorDown(c);

    if (e.touches.length > 1) return;

    const maybeSpotId = eventWithinSpot(e);

    if (maybeSpotId !== null) {
      log(`Setting single spot: ${getSpotById(spots, maybeSpotId).name}`);
      setSingleSpot(maybeSpotId);
    }
  };
}
