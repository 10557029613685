import { StringLiteral } from "typescript";

export default function eventWithinSpot(
  e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
): string {
  if (!(e.target instanceof HTMLElement)) return null;
  const spot = e.target.closest("[data-spot-id]");
  if (!spot) return null;
  return spot.getAttribute("data-spot-id");
}
