import * as React from "react";
import styled from "styled-components";
import { SmallScreenFill } from "./Fill";
import desktop from "../img/desktop.svg";

const Outer = styled.div`
  padding: 64px;
`;

const Inner = styled.div`
  text-align: center;

  h2 {
    font-size: 30px;
    font-weight: bold;
  }

  img {
    max-width: 100%;
  }

  p {
    margin-bottom: 0;
  }
`;

const MobileStopSign = () => {
  return (
    <SmallScreenFill>
      <Outer>
        <Inner>
          <img src={desktop} alt="" />
          <h2>Looks like you're on mobile.</h2>
          <p>
            Pick-a-spot layouts must be built on a <b>desktop</b> or{" "}
            <b>tablet</b>.
          </p>
        </Inner>
      </Outer>
    </SmallScreenFill>
  );
};

export default MobileStopSign;
