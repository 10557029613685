/// <reference path="../index.d.ts" />

import * as React from "react";
import styled from "styled-components";
import Flow from "../types/Flow";
import BaseShape from "../types/BaseShape";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import Radio from "../atoms/ShapeRadio";

import nameImg from "../img/onboarding_name.svg";
import spotCountImg from "../img/onboarding_spotcount.svg";
import squareImg from "../img/square.svg";
import wideRect from "../img/wide_rect.svg";
import longRect from "../img/long_rect.svg";
import uShape from "../img/u_shape.svg";
import type { AppProps, SetAppProps } from "../types/AppProps";
import {
  LAYOUT_NAME_MAX_LENGTH,
  SPOT_COUNT_MAX,
  Z_INDICES,
} from "../constants";
import { percentEncode } from "../utils/percentEncodingHack";

export const Modal = styled.form`
  align-items: center;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  color: var(--color-text-secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50vw;
  padding: 32px;
  position: absolute;
  top: 50vh;
  transform: translate(-50%, -50%);
  width: 655px;
  z-index: ${Z_INDICES.ONBOARDING_MODAL};

  h2,
  h3 {
    color: var(--color-text);
  }

  h2 {
    font-weight: bold;
    margin: 32px 0 0;
  }

  h3 {
    font-weight: bold;
  }

  img {
    max-height: 200px;
    max-width: 100%;
  }
`;

export const ModalShadow = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: ${Z_INDICES.ONBOARDING_MODAL_SHADOW};
`;

export const StyledNav = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 32px;
  justify-content: space-between;
  width: 100%;
`;

const Nav = ({
  next,
  nextDisabled,
  nextLabel = "Next",
  prev,
  step,
}: {
  next: () => void;
  nextDisabled: boolean;
  nextLabel?: string;
  prev: () => void;
  step: number;
}) => {
  return (
    <StyledNav>
      <Button
        data-testid="OnboardingModal__Next"
        onClick={next}
        disabled={nextDisabled}
        tabIndex={0}
      >
        {nextLabel}
      </Button>
      <span>Step {step}/4</span>
      <Button
        data-testid="OnboardingModal__Back"
        level="secondary"
        onClick={prev}
      >
        Back
      </Button>
    </StyledNav>
  );
};

const RadioContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
  width: 100%;
`;

const Warning = styled.small`
  color: var(--color-UInegative);
`;

const OnboardingModal = ({
  flow,
  setFlow,
  name,
  setName,
  setRedirect,
  setShape,
  setSpotCount,
  spotCount,
}: AppProps & SetAppProps) => {
  const focusRef = React.useRef<HTMLInputElement>();
  React.useEffect(() => {
    if (focusRef.current) focusRef.current.focus();
  }, [flow]);

  const inputValueToSpotCount = parseInt;

  return (
    <>
      <ModalShadow />
      <Modal data-testid="OnboardingModal" onSubmit={e => e.preventDefault()}>
        {flow === Flow.BEGIN ? (
          <>
            <h2 data-testid="OnboardingModal__Begin--Title">
              Create a new layout
            </h2>
            <p>Let's create a new pick-a-spot layout for your classroom!</p>
            <Button
              data-testid="OnboardingModal__Begin--Button"
              onClick={() => {
                setFlow(Flow.NAME);
              }}
              ref={focusRef}
              style={{ margin: "16px 0 32px" }}
            >
              Begin
            </Button>
          </>
        ) : flow === Flow.NAME ? (
          <>
            <img alt="" src={nameImg} />
            <h3 data-testid="OnboardingModal__Name--Title">
              What do you want to name this layout?
            </h3>
            <Input
              data-testid="OnboardingModal__Name--Input"
              maxLength={LAYOUT_NAME_MAX_LENGTH}
              onInput={e => setName(e.currentTarget.value)}
              placeholder="Name"
              ref={focusRef}
              value={name}
            />
            <Nav
              next={() => {
                setFlow(Flow.SPOT_COUNT);
              }}
              nextDisabled={name.length === 0}
              prev={() => {
                setFlow(Flow.BEGIN);
              }}
              step={2}
            />
          </>
        ) : flow === Flow.SPOT_COUNT ? (
          <>
            <img alt="" src={spotCountImg} />
            <h3 data-testid="OnboardingModal__SpotCount--Title">
              How many spots are in this class?
            </h3>
            <p style={{ margin: "0 -60px 4px 0" }}>
              There are{" "}
              <Input
                data-testid="OnboardingModal__SpotCount--Input"
                defaultValue={spotCount ? spotCount : 30}
                inputMode="numeric"
                max={SPOT_COUNT_MAX}
                min={1}
                onKeyUp={() => {
                  const value = inputValueToSpotCount(focusRef.current.value);
                  setSpotCount(isNaN(value) ? 0 : value);
                }}
                onBlur={e => {
                  const value = inputValueToSpotCount(focusRef.current.value);
                  setSpotCount(isNaN(value) ? 0 : value);
                  e.currentTarget.value = (isNaN(value) ? 0 : value).toString();
                }}
                step={1}
                ref={focusRef}
                pattern="\d*"
                type="number"
                style={{
                  display: "inline-block",
                  margin: "0 8px",
                  width: "180px",
                }}
                valid={
                  spotCount === null
                    ? true
                    : spotCount >= 1 && spotCount <= SPOT_COUNT_MAX
                }
              />{" "}
              spots in this class.
            </p>
            {spotCount !== null &&
              (spotCount < 1 || spotCount > SPOT_COUNT_MAX) && (
                <Warning>Enter a value between 1-{SPOT_COUNT_MAX}.</Warning>
              )}
            <Nav
              next={() => {
                setSpotCount(inputValueToSpotCount(focusRef.current.value));
                setFlow(Flow.SHAPE);
              }}
              nextDisabled={
                spotCount === null
                  ? false
                  : spotCount < 1 || spotCount > SPOT_COUNT_MAX
              }
              prev={() => {
                setFlow(Flow.NAME);
              }}
              step={3}
            />
          </>
        ) : flow === Flow.SHAPE ? (
          <>
            <h3
              data-testid="OnboardingModal__Shape--Title"
              style={{ marginBottom: 0 }}
            >
              Choose the shape of your layout.
            </h3>
            <p style={{ margin: "4px 0 48px" }}>
              This is a general starting point and can be edited later.
            </p>
            <RadioContainer>
              <Radio
                image={squareImg}
                isDefault
                label="Square"
                onChange={e => setShape(BaseShape.SQUARE)}
                shape={BaseShape.SQUARE}
                ref={focusRef}
              />
              <Radio
                image={wideRect}
                label="Wide Rectangle"
                onChange={e => setShape(BaseShape.WIDE_RECTANGLE)}
                shape={BaseShape.WIDE_RECTANGLE}
              />
              <Radio
                image={longRect}
                label="Long Rectangle"
                onChange={e => setShape(BaseShape.LONG_RECTANGLE)}
                shape={BaseShape.LONG_RECTANGLE}
              />
              <Radio
                image={uShape}
                label="U Shape"
                onChange={e => setShape(BaseShape.U_SHAPE)}
                shape={BaseShape.U_SHAPE}
              />
            </RadioContainer>
            <Nav
              next={() => {
                setRedirect(`/layout/${percentEncode(name)}`);
                setFlow(null);
              }}
              nextDisabled={false}
              nextLabel="Create Layout"
              prev={() => {
                setFlow(Flow.SPOT_COUNT);
              }}
              step={4}
            />
          </>
        ) : null}
      </Modal>
    </>
  );
};

export default OnboardingModal;
