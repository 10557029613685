import * as React from "react";
import { isArray } from "lodash";
import { apiPost, login, logout } from "../auth";
import { AppProps, SetAppProps } from "../types/AppProps";
import Spot, { SPOT_HEIGHT, SPOT_WIDTH } from "../types/Spot";
import log from "./log";
import { getClassroomID, getLayoutData } from "./store";
import type ApiSpot from "../types/ApiSpot";
import ToastStyle from "../types/ToastStyle";
import boundingBoxOfSpots from "./bbOfSpots";
import { XProps } from '../types/zoid';
import { useXProps } from '../hooks/useXprops';

function mapDimension(n: number, which: "width" | "height"): string {
  return (
    Math.round((n * 100) / (which === "width" ? SPOT_WIDTH : SPOT_HEIGHT)) / 100
  ).toString();
}

function unmapDimension(s: string, which: "width" | "height"): number {
  return parseFloat(s) * (which === "width" ? SPOT_WIDTH : SPOT_HEIGHT);
}

export function mapSpots(spots: Spot[]): ApiSpot[] {
  // normalize to ensure that no spot gets sent
  // to the API with a negative x/y coordinate
  const bb = boundingBoxOfSpots(spots);
  if (bb.x > 0) bb.x = 0;
  if (bb.y > 0) bb.y = 0;

  return spots.map(s => {
    return {
      id: s.id,
      name: s.name,
      x_position: mapDimension(s.x - bb.x, "width"),
      y_position: mapDimension(s.y - bb.y, "height"),
      spot_type: {
        id: s.type.id,
        is_secondary: s.type.priority === "secondary",
        name: s.type.name,
      },
      is_default_held: !s.available,
    };
  });
}

export function unmapSpots(spots: ApiSpot[]): Spot[] {
  return spots.map(s => {
    const x = unmapDimension(s.x_position, "width");
    const y = unmapDimension(s.y_position, "height");
    const spot = new Spot(x, y);
    spot.id = s.id,
    spot.name = s.name;
    spot.type = {
      id: s.spot_type.id,
      name: s.spot_type.name,
      priority: s.spot_type.is_secondary ? "secondary" : "primary",
    };
    spot.available = !s.is_default_held;
    return spot;
  });
}

const LogInBtnComponent = () => {
  const { hash, pathname, search } = window.location;
  const handleOnClick = () => {
    logout();
    login(`${pathname}${search}${hash}`);
  };

  return (
    <>
      {"You must be logged into your account to save this layout. Please "}
      {<button onClick={handleOnClick}>log in</button>}
      {" and try again."}
    </>
  );
};

export default function saveLayoutToApi({
  name,
  setToast,
  setToastDuration,
  onSaveLayout,
  isEdit
}: AppProps & SetAppProps & { onSaveLayout: XProps['onSaveLayout'], isEdit: boolean } ) {
  /**
 * Get isEdit mode from query params
 */

  if (!getClassroomID()) {
    setToast(
      "No classroom ID found. Please open the PAS Layout Builder from your classroom admin",
      ToastStyle.ERROR
    );
    setToastDuration(7);
    return;
  }

  log(`Saving layout ${name}`);

  const { spots } = getLayoutData(name);
  // map spots into a format suitable for the API.
  const mappedSpots = mapSpots(spots);

  const patchSpotsPayload = mappedSpots.map(spot => {
    const { id, name, x_position, y_position } = spot
    return { 
      id, 
      name, 
      x_position, 
      y_position 
    }
  });

  const postSpotsPayload = mappedSpots.map(spot => {
    const { 
      is_default_held, 
      name, 
      spot_type, 
      x_position, 
      y_position 
    } = spot
    return { 
      is_default_held, 
      name, 
      spot_type, 
      x_position, 
      y_position
    }
  });

  const postData = {
      name,
      is_active: true,
      classroom: {
        id: getClassroomID(),
      },
      capacity: spots.length,
      is_pick_a_spot: true,
      spots: postSpotsPayload,
    }

  const patchData = {
    classroom: {
      id: getClassroomID(),
    },
    spots: patchSpotsPayload
  }

  const { layoutId } = useXProps();

  const endpoint = `/api/studio/v1/layouts${isEdit ? `/${layoutId}` : ''}`;
  const method = isEdit ? 'PATCH' : 'POST';
  const data = isEdit ? patchData : postData;

  return apiPost(endpoint, data, method)
    .catch(err => {
      setToastDuration(7);
      setToast(
        "There was an error saving your layout. Please try again later or contact Customer Support for assistance.",
        ToastStyle.ERROR
      );
    })
    .then(async (res) => {
      if (!res) return;
      let message: string | (() => JSX.Element) = "";
      let isError = false;
      setToastDuration(3);

      if (isArray(res.name)) {
        isError = true;
        message = res.name.join("\n");
      } else if (res.non_field_errors) {
        isError = true;
        message = res.non_field_errors;
      } else if (res.status) {
        isError = true;
        switch (res.status) {
          case 401:
            message = LogInBtnComponent;
            break;
          case 403:
            message =
              "You don't have permission to save this layout. Contact your manager to update your permissions.";
            break;
          case 422:
          case 500:
            message =
              "There was an error saving your layout. Please try again later or contact Customer Support for assistance.";
            break;
        }
      } else {
        await onSaveLayout(data.classroom.id);
      }
      setToast(
        message, 
        isError ? ToastStyle.ERROR : ToastStyle.SUCCESS, 
        () => async () => { 
          if (!isError) {
            await onSaveLayout(data.classroom.id); 
          } 
        }
      );
      console.log(res);
    });
}
