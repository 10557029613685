import queryString from "query-string";

class URLParser {
  get url() {
    return window.location.origin + "/" + window.location.pathname;
  }

  /**
   * @returns The layout name, or an empty string (failure)
   */
  getLayout(path?: string): string {
    if (path) {
      return path.split("/")[0] === "layout" ? path.split("/")[1] : "";
    } else {
      const { url } = this;
      const params = url.split("/").filter(a => a !== "");
      if (params[params.length - 2] === "layout") {
        return decodeURIComponent(params[params.length - 1]);
      }
      return "";
    }
  }

  /**
   * @returns The layout ID (if there is one as a query param)
   */
  getLayoutFromParam(): string {
    const params = queryString.parse(window.location.search);
    if (params.layout && typeof params.layout === "string") {
      return params.layout;
    }
    return "";
  }

  /**
   * @returns The classroom ID, or an empty string (failure)
   */
  getClassroom(route?: string): string {
    const { url } = this;
    const params = (route || url).split("/").filter(a => a !== "");
    if (params[params.length - 2] === "classroom") {
      return params[params.length - 1];
    }
    return "";
  }
}

export default new URLParser();
