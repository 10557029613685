import Point from "../types/Point";

/**
 * Given the cursor (where the mouse first went down),
 * and the cursor (where it is now),
 * return the bounding box (x, y, width, height) that describes this relationship.
 * Used mainly for netting and selecting spots.
 * @param cursorDown
 * @param cursor
 */
export default function netBoundingBox(
  cursorDown: Point,
  cursor: Point
): { x: number; y: number; width: number; height: number } {
  // safety check
  if (cursorDown === null || cursor === null) return null;

  // x/y always at the upper-left corner
  const x = Math.min(cursorDown.x, cursor.x);
  const y = Math.min(cursorDown.y, cursor.y);

  // width/height always positive (or at least non-negative)
  const width = Math.abs(cursorDown.x - cursor.x);
  const height = Math.abs(cursorDown.y - cursor.y);

  return { x, y, width, height };
}
