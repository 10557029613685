/// <reference path="../index.d.ts" />

import styled, { css } from "styled-components";
import kebab from "../img/kebab.svg";
import check from "../img/check-circle.svg";
import checkRed from "../img/check-circle-red.svg";
import { SPOT_HEIGHT, SPOT_WIDTH } from "../types/Spot";
import { Z_INDICES } from "../constants";

const isSelectedCSS = css`
  border-color: var(--color-primary);
`;

/* !important overrides hover */
const invalidCSS = css`
  border-color: var(--color-UInegative-dark) !important;
`;

const StyledSpot = styled.div<{
  available: boolean;
  cursorOverTrash: boolean;
  interactive?: boolean;
  invalid?: boolean;
  isSelected?: boolean;
  isSingular?: boolean;
  shouldOffset: boolean;
}>`
  align-items: center;
  background-color: #fff;
  border: 1px solid #1c1c1e;
  border-radius: 3px;
  display: flex;
  font-family: var(--font-family);
  flex-direction: column;
  height: ${SPOT_WIDTH}px;
  justify-content: center;
  opacity: ${props => (props.shouldOffset && !props.cursorOverTrash ? 0.8 : 1)};
  position: absolute;
  width: ${SPOT_HEIGHT}px;
  transform-origin: 100% -50%;
  z-index: ${props =>
    props.shouldOffset ? Z_INDICES.SPOT_SELECTED : Z_INDICES.SPOT};

  ${props =>
    props.cursorOverTrash &&
    css`
      transition: 0.2s left, 0.2s top;
    `}

  &:before,
  &:after {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:after {
    background-position: calc(100% - 8px) 8px;
    background-repeat: no-repeat;
    pointer-events: none;

    ${props =>
      props.isSelected &&
      css`
        background-image: url(${check}) !important;
        background-position: calc(100% - 4px) 4px;
      `}

    ${props =>
      props.invalid &&
      props.isSelected &&
      css`
        background-image: url(${checkRed}) !important;
      `}
  }

  &:active,
  &:hover {
    &:after {
      background-image: url(${kebab});
    }
  }

  &:before {
    background-image: repeating-linear-gradient(
      45deg,
      #e7e9eb,
      #e7e9eb 4px,
      transparent 4px,
      transparent 8px
    );
    background-repeat: repeat-x;
    opacity: ${props => (!props.available ? 0.8 : 0)};
    transition: 0.2s opacity;
  }

  ${props =>
    !props.interactive &&
    css`
      pointer-events: none;
      * {
        pointer-events: none;
      }
    `}

  ${props => props.isSelected && isSelectedCSS}
  ${props => props.invalid && invalidCSS}

  &:hover {
    border-color: var(--color-primary);
  }

  &:focus {
    border-color: var(--color-primary);
    outline: 0;
  }
`;

export default StyledSpot;
