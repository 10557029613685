import type { AppProps, SetAppProps } from "../types/AppProps";
import bbContainsPoint from "../utils/bbContainsPoint";
import concatUniq from "../utils/concatUniq";
import determineValidDraggingLocation from "../utils/determineValidSpotLocation";
import { getSpotById } from "../utils/getSpotById";
import log from "../utils/log";
import netContainsSpot from "../utils/netContainsSpot";

/**
 * Both `onMouseMove` and `onTouchMove` use the following code to:
 * - set `netSelected` spots when netting
 * - set `isValidDraggingLocation` when dragging
 * - set `isDragging` if the user has started dragging
 */
export default function abstractMove(
  props: AppProps &
    SetAppProps & {
      trash: React.RefObject<HTMLButtonElement>;
    }
) {
  const {
    cursor,
    cursorDown,
    isDragging,
    isNetting,
    netSelected,
    selected,
    setIsDragging,
    setIsValidDraggingLocation,
    setNetSelected,
    setSelected,
    singleSpot,
    spots,
    trash,
  } = props;

  if (isNetting) {
    const selection = spots.filter(spot => netContainsSpot({ spot, ...props }));
    return setNetSelected(selection.map(s => s.id));
  }

  if (isDragging && cursor && cursorDown) {
    const cursorOverTrash =
      trash.current && cursor
        ? bbContainsPoint(trash.current.getBoundingClientRect(), cursor)
        : false;

    if (cursorOverTrash) {
      return setIsValidDraggingLocation(true);
    }

    const validDraggingLocation = determineValidDraggingLocation({
      ...props,
      selected: concatUniq(selected, netSelected),
    });

    return setIsValidDraggingLocation(validDraggingLocation);
  }

  if (singleSpot !== null) {
    const spot = getSpotById(spots, singleSpot);

    if (
      cursor &&
      cursorDown &&
      (Math.abs(cursor.x - cursorDown.x) > 2 ||
        Math.abs(cursor.y - cursorDown.y) > 2)
    ) {
      log(`Started dragging from spot ${spot.name} (${spot.type.name})`);
      setIsDragging(true);
      /**
       * If this spot is not selected, then others should unselect. Consider the following:
       * Suppose the spot in question is spot 4, and spots 1, 2, and 3 are selected.
       * If the user drags spot 4 (without first selecting it), then
       * all spots 1, 2, and 3 should become unselected.
       */
      if (!selected.includes(singleSpot)) setSelected([]);
    }

    return;
  }
}
