import * as React from "react";
import styled from "styled-components";

const StyledSpotNodeType = styled.span<{ fontSize: number }>`
  color: var(--color-grey-4);
  font-size: ${props => props.fontSize}px;
  max-height: ${props => 2.5 * props.fontSize}px;
  pointer-events: none;
  text-align: center;
  text-overflow: ellipsis;

  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const SpotNodeType = ({
  children,
  zoom,
}: React.HTMLAttributes<HTMLSpanElement> & {
  zoom: number;
}) => {
  let fontSize = 12;
  if (zoom > 1) fontSize = 11;
  if (zoom > 1.25) fontSize = 10;
  if (zoom > 1.5) fontSize = 9;
  if (zoom > 1.75) fontSize = 8;
  return (
    <StyledSpotNodeType fontSize={fontSize}>{children}</StyledSpotNodeType>
  );
};

export default React.memo(SpotNodeType);
