import * as React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import zoid from "zoid";

window.LayoutBuilderComponent = zoid.create({
    /**
     * we may not always want zoid components to take up the entire available space
     * so mileage may vary on this technique
     */
    dimensions: {
        width: '80%',
        height: '50%',
    },
    // tag means loading the correct component in the child window or frame
    tag: 'layout-builder-component',
    // the full url that will be loaded when your component is rendered
    url: () => `${window.location.origin}/`,
});

ReactDOM.render(<App />, document.getElementById("app"));
