import Point from "../types/Point";

export default function bbContainsPoint(
  bb: Point & { width: number; height: number },
  point: Point
): boolean {
  return (
    point.x >= bb.x &&
    point.x <= bb.x + bb.width &&
    point.y >= bb.y &&
    point.y <= bb.y + bb.height
  );
}
