import styled from "styled-components";

const Fill = styled.div`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;

export default Fill;

const LargeScreenFill = styled(Fill)`
  @media screen and (max-width: 428px) {
    display: none;
  }
  @media screen and (max-height: 428px) {
    display: none;
  }
`;

const SmallScreenFill = styled(Fill)`
  display: none;
  @media screen and (max-width: 428px) {
    display: block;
  }
  @media screen and (max-height: 428px) {
    display: block;
  }
`;

export { LargeScreenFill };
export { SmallScreenFill };
