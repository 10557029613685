import styled from "styled-components";

const Toggle = styled.button<{ active: boolean }>`
  background: #fafbfc;
  border: 1px solid
    var(${props => (props.active ? "--color-primary" : "--color-grey-4")});
  border-radius: 12px;
  cursor: pointer;
  height: 24px;
  margin-right: 16px;
  padding: 0;
  vertical-align: middle;
  width: 45px;

  &:after {
    background: ${props => (props.active ? "var(--color-primary)" : "#BABEC2")};
    border: 1px solid
      var(
        ${props =>
          props.active ? "--color-primary-dark" : "--color-text-secondary"}
      );
    border-radius: 10px;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 20px;
    left: 1px;
    position: absolute;
    top: 1px;
    transform: translateX(${props => (props.active ? 20 : 0)}px);
    transition: 0.2s transform, 0.2s background-color, 0.2s border-color;
    width: 20px;
  }

  &:focus {
    outline: 0;
  }
`;

export default Toggle;
