import * as React from "react";
import styled from "styled-components";
import { SPOT_COUNT_MAX } from "../constants";
import kebab from "../img/addspot-kebab.svg";
import type { AppProps, SetAppProps } from "../types/AppProps";
import type SpotType from "../types/SpotType";
import autoSpotNumber from "../utils/autoSpotNumber";
import SecondaryIcon from "../atoms/SecondaryIcon";

const StyledAddSpotGroup = styled.button`
  align-items: center;
  background: transparent;
  border: 0 none;
  border-bottom: 1px solid var(--color-grey-3);
  cursor: pointer;
  display: flex;
  font-family: var(--font-family);
  font-size: 16px;
  padding: 16px 0;
  text-align: left;
  user-select: none;
  width: 100%;

  &:focus {
    outline: 0;
  }

  img {
    margin-right: 12px;
  }

  &:first-child {
    margin-top: -16px;
  }
`;

const SpotIcon = styled.div`
  align-items: center;
  border: 1px solid #000;
  border-radius: 3px;
  display: flex;
  flex-shrink: 0;
  font-size: 14px;
  height: 40px;
  margin-right: 12px;
  justify-content: center;
  width: 40px;
`;

const AddSpotGroup = React.memo(
  ({
    children,
    spots,
    spotType,
    ...props
  }: React.ButtonHTMLAttributes<HTMLButtonElement> &
    AppProps &
    SetAppProps & {
      spotType: SpotType;
    }) => {
    const { setIsAddingSpot } = props;
    const start = () => setIsAddingSpot(spotType);

    return (
      <StyledAddSpotGroup
        disabled={spots.length === SPOT_COUNT_MAX}
        onMouseDown={e => {
          if (e.buttons === 2) return;
          start();
        }}
        onTouchStart={start}
      >
        <img src={kebab} alt="" />
        <SpotIcon>{autoSpotNumber(spots)}</SpotIcon>
        <span>
          {spotType.name}{" "}
          {spotType.priority === "secondary" && <SecondaryIcon type="side" />}
        </span>
      </StyledAddSpotGroup>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.spots !== nextProps.spots) return false;
    return true;
  }
);

export default AddSpotGroup;
