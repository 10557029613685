import type { AppProps } from "../types/AppProps";
import Spot, { SPOT_HEIGHT, SPOT_WIDTH } from "../types/Spot";
import canvasToScreen from "./canvasToScreen";
import netBoundingBox from "./netBoundingBox";

/**
 * This is basically point-in-bounding-box detection --
 * does the spot (or part of it) fall within the bounding box
 * described by the cursor and cursor?
 * @param cursorDown
 * @param cursor
 * @param spot
 */
export default function netContainsSpot({
  spot,
  ...props
}: AppProps & { spot: Spot }): boolean {
  // safety check
  const { cursor, cursorDown } = props;
  if (cursor === null || cursorDown === null || spot === null) return false;

  const box = netBoundingBox(cursorDown, cursor);

  // transform the spot from canvas coordinates to screen coordinates
  // so that we can compare it to the cursor net bounding box
  const spotUpperLeft = canvasToScreen(spot, props);
  const spotLowerRight = canvasToScreen(
    {
      x: spot.x + SPOT_WIDTH,
      y: spot.y + SPOT_HEIGHT,
    },
    props
  );

  if (
    spotLowerRight.x > box.x &&
    spotLowerRight.y > box.y &&
    spotUpperLeft.x < box.x + box.width &&
    spotUpperLeft.y < box.y + box.height
  ) {
    return true;
  }

  return false;
}
