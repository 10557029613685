import { calculateSidePanelWidth } from "../components/SidePanel";
import { topnavHeight } from "../components/TopNav";
import type BoundingBox from "../types/BoundingBox";

export default function layoutBB(isEdit = false): BoundingBox {
  const subtractFromWidth = isEdit ? 0 : calculateSidePanelWidth();
  return {
    x: 0,
    y: topnavHeight,
    width: window.innerWidth - subtractFromWidth,
    height: window.innerHeight - topnavHeight,
  };
}
