import { clamp, delay, round } from "lodash";
import * as React from "react";
import styled from "styled-components";
import type { AppProps, SetAppProps } from "../types/AppProps";
import log from "../utils/log";

const ZOOM_MAX = 2;
const ZOOM_MIN = 0.25;
const ZOOM_INCREMENT = 0.25;

const Container = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 25px;
  display: flex;
  height: 50px;
  padding: 0 24px;
  position: absolute;
  left: 48px;
  bottom: 48px;

  span {
    display: inline-block;
    margin-right: 24px;
    text-align: right;
    width: 55px;
  }

  button {
    appearance: none;
    background: transparent;
    border: 0 none;
    cursor: pointer;
    font: inherit;
    font-size: 28px;
    padding: 0 5px;
    top: -2px;
    touch-action: manipulation;
  }
`;

// Ensure that the zoom level does not go out of the established bounds
export function clampZoom(zoom: number): number {
  return round(clamp(zoom, ZOOM_MIN, ZOOM_MAX), 2);
}

function coerceZoom(zoom: number, delta: number): number {
  return round((zoom + delta) / ZOOM_INCREMENT) * ZOOM_INCREMENT;
}

function strFromZoom(zoom: number): string {
  return ((zoom * 100) | 0).toString();
}

const ZoomControls = ({
  layout,
  isZooming,
  setIsZooming,
  setZoom,
  zoom,
}: AppProps &
  SetAppProps & {
    isZooming: boolean;
    layout: React.RefObject<HTMLDivElement>;
    setIsZooming: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
  const [keepZooming, setKeepZooming] = React.useState<number>(null);

  const onWheel = (e: WheelEvent) => {
    e.preventDefault();
    setIsZooming(true);
    setZoom(clampZoom(zoom - Math.sign(e.deltaY) * 0.05));
    setTimeout(() => {
      setIsZooming(false);
    }, 500);
  };

  React.useEffect(() => {
    layout.current?.addEventListener("wheel", onWheel);
    return () => layout.current?.removeEventListener("wheel", onWheel);
  }, [zoom]);

  /**
   * Add keyboard shortcuts (+/-) for zooming in/out.
   * NOTE to add e.stopPropagation() on inputs everywhere to prevent
   * this from occurring when a user is typing.
   */
  React.useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [zoom]);

  const zoomIn = () => {
    if (zoom >= ZOOM_MAX) return;
    setIsZooming(true);
    const newZoom = coerceZoom(zoom, ZOOM_INCREMENT);
    setZoom(newZoom);
    log("Zooming in to", newZoom);
    setTimeout(() => setIsZooming(false), 500);
  };

  const zoomOut = () => {
    if (zoom <= ZOOM_MIN) return;
    setIsZooming(true);
    const newZoom = coerceZoom(zoom, -ZOOM_INCREMENT);
    setZoom(newZoom);
    log("Zooming out to", newZoom);
    setTimeout(() => setIsZooming(false), 500);
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === "+" || e.key === "=") {
      zoomIn();
    } else if (e.key === "-") {
      zoomOut();
    }
  };

  /**
   * This effect lets us keep zooming in the chosen direction
   * if the user has clicked on a button and has not released it.
   */
  React.useEffect(() => {
    if (keepZooming === null) return;
    if (keepZooming > 0 && zoom + keepZooming > ZOOM_MAX) {
      setIsZooming(false);
      setKeepZooming(null);
      delay(() => setZoom(ZOOM_MAX), 100);
    } else if (keepZooming < 0 && zoom - keepZooming < ZOOM_MIN) {
      setIsZooming(false);
      setKeepZooming(null);
      delay(() => setZoom(ZOOM_MIN), 100);
    } else if (zoom + keepZooming === clampZoom(zoom + keepZooming)) {
      setIsZooming;
      const newZoom =
        Math.round((zoom + keepZooming) / ZOOM_INCREMENT) * ZOOM_INCREMENT;
      delay(() => setZoom(newZoom), 100);
    }
  }, [keepZooming, zoom]);

  const onMouseDown = (delta: number) => {
    return (e: React.MouseEvent | React.TouchEvent) => {
      if (isZooming) return;
      e.preventDefault();
      if (e instanceof MouseEvent && e.buttons === 2) return;
      setKeepZooming(delta);
    };
  };

  const onMouseUp = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
    setKeepZooming(null);
  };

  return (
    <Container
      data-testid="ZoomControls"
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseUp}
      onTouchEnd={onMouseUp}
    >
      <span>{strFromZoom(zoom)}%</span>
      <button
        data-testid="ZoomControls__ZoomIn"
        onClick={e => {
          if (isZooming) return;
          e.preventDefault();
          if (e.buttons === 2) return;
          zoomIn();
        }}
        onMouseDown={onMouseDown(ZOOM_INCREMENT)}
        onTouchStart={onMouseDown(ZOOM_INCREMENT)}
      >
        +
      </button>
      <button
        data-testid="ZoomControls__ZoomOut"
        onClick={e => {
          if (isZooming) return;
          e.preventDefault();
          if (e.buttons === 2) return;
          zoomOut();
        }}
        onMouseDown={onMouseDown(-ZOOM_INCREMENT)}
        onTouchStart={onMouseDown(-ZOOM_INCREMENT)}
      >
        &ndash;
      </button>
    </Container>
  );
};

export default ZoomControls;
