import type { AppProps, SetAppProps } from "../types/AppProps";
import eventWithinSpot from "../utils/eventWithinSpot";
import { getSpotById } from "../utils/getSpotById";
import log from "../utils/log";

export default function onMouseDown(props: AppProps & SetAppProps) {
  const { setCursorDown, setSingleSpot, spots } = props;

  return function onMouseDown(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    setCursorDown({ x: e.pageX, y: e.pageY });

    const maybeSpotId = eventWithinSpot(e);

    if (maybeSpotId !== null) {
      if (e.button === 2) return;
      log(`Setting single spot: ${getSpotById(spots, maybeSpotId).name}`);
      setSingleSpot(maybeSpotId);
    }
  };
}
