import * as React from "react";
import styled, { css } from "styled-components";

const StyledInput = styled.input<{ ref?: any; valid: boolean }>`
  background: #fff;
  border: 2px solid #d7d9db;
  border-radius: 4px;
  display: block;
  font-family: var(--font-family);
  font-size: 18px;
  padding: 16px 8px;
  transition: 0.2s box-shadow;

  &:focus {
    outline: 0;
    border-color: var(--color-primary);
  }

  ${props =>
    !props.valid &&
    css`
      /* !important to override focus state */
      box-shadow: inset 0 0 0 1px var(--color-UInegative) !important;
    `}
`;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  valid?: boolean;
}

export default React.forwardRef(
  ({ valid = true, ...props }: InputProps, ref) => {
    return <StyledInput ref={ref} valid={valid} {...props} />;
  }
);
