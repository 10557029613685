import { v1 as uuid } from "uuid";
import type BoundingBox from "./BoundingBox";
import SpotType from "./SpotType";

// constants for visual layout
export const SPOT_WIDTH = 70;
export const SPOT_HEIGHT = 70;
export const SPOT_UNIT = SPOT_WIDTH / 5;

/**
 * Data representation of a spot. The Node (i.e. React Component)
 * is declared separately, in `src/atoms/SpotNode.tsx`
 */
class Spot implements BoundingBox {
  available: boolean;
  id: string = uuid();
  name: string = "";
  type: SpotType;
  x: number;
  y: number;
  width: number;
  height: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
    this.width = SPOT_WIDTH;
    this.height = SPOT_HEIGHT;

    this.available = true;
    this.type = null;
  }

  clone() {
    const s = new Spot(this.x, this.y);
    s.available = this.available;
    s.type = Object.assign({}, this.type);
    return s;
  }
}

export default Spot;
