import BoundingBox from "../types/BoundingBox";
import Spot, { SPOT_HEIGHT, SPOT_WIDTH } from "../types/Spot";

/**
 * Given an array of Spots, return their bounding box.
 * All units and coordinates are in canvas space.
 * @param {Spot[]} spots
 */
export default function boundingBoxOfSpots(spots: Spot[]): BoundingBox {
  let bb: BoundingBox = null;

  const max = { x: -Infinity, y: -Infinity };

  spots.forEach(spot => {
    if (bb === null) {
      bb = {
        x: spot.x,
        y: spot.y,
        width: SPOT_WIDTH,
        height: SPOT_HEIGHT,
      };
    } else {
      if (spot.x < bb.x) bb.x = spot.x;
      if (spot.y < bb.y) bb.y = spot.y;
    }
    if (spot.x + SPOT_WIDTH > max.x) max.x = spot.x + SPOT_WIDTH;
    if (spot.y + SPOT_HEIGHT > max.y) max.y = spot.y + SPOT_HEIGHT;
  });

  if (bb === null) return bb;

  bb.width = max.x - bb.x;
  bb.height = max.y - bb.y;

  return bb;
}
