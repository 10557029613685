/// <reference path="../index.d.ts" />

import * as React from "react";
import styled from "styled-components";
import trash from "../img/trash.svg";
import trashWhite from "../img/trash-white.svg";
import type { AppProps, SetAppProps } from "../types/AppProps";
import { sidePanelWidth, sidePanelWidthMed } from "../components/SidePanel";
import { BREAKPOINT_M } from "../constants";

const StyledTrash = styled.button<{
  active: boolean;
  isPanning: boolean;
}>`
  appearance: none;
  background-color: ${props =>
    props.active ? "var(--color-UInegative)" : "#fff"};
  bottom: 48px;
  border: 0 none;
  border-radius: 50%;
  box-shadow: 0 0 42px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  height: 60px;
  position: absolute;
  right: ${sidePanelWidth + 48}px;
  text-indent: -9999px;
  transition: 0.2s background-color;
  width: 60px;

  &:focus {
    outline: 0;
  }

  &:after {
    background-image: url(${trash});
    background-position: center center;
    background-repeat: no-repeat;
    content: "";
    display: block;
    filter: brightness(${props => (props.active ? 3 : 1)});
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: 0.2s filter;
    width: 100%;
  }

  @media screen and (max-width: ${BREAKPOINT_M}px) {
    right: ${sidePanelWidthMed + 48}px;
  }
`;

const Trash = React.forwardRef(
  (
    props: AppProps &
      SetAppProps & {
        active: boolean;
      },
    ref: any
  ) => {
    const { isPanning, netSelected, selected, singleSpot } = props;
    const active =
      singleSpot !== null || selected.length > 0 || netSelected.length > 0;
    return (
      <StyledTrash active={active} isPanning={isPanning} ref={ref}>
        Trash
      </StyledTrash>
    );
  }
);

export default Trash;
