import * as React from "react";
import Button from "../atoms/Button";
import { Modal, ModalShadow } from "./OnboardingModal";
import styled from "styled-components";
import times from "../img/times.svg";
import { ModalProps } from "../types/ModalProps";

const StyledHeader = styled.header`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const StyledIcon = styled.img`
    cursor: pointer;
`;

const StyledTitle = styled.p`
    color: #000000;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0;
`;

const StyledBody = styled.p`
    color: #31393B;
    text-align: center;
    padding-right: 3rem;
    padding-left: 3rem;
    margin-bottom: 3rem;
`;

const StyledFooter = styled.footer`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;


const ConfirmSaveModal = ({
    isLoading = false,
    isModalShowing, 
    toggleModal,
    modalAction
}: ModalProps) => {
    return (
        <>
            {isModalShowing && (
                <>
                    <ModalShadow />
                    <Modal>
                        <StyledHeader>
                            <StyledIcon
                                onClick={toggleModal}
                                src={times}
                                alt=""
                            />
                        </StyledHeader>
                        <StyledTitle>
                            Are you sure you want to save?
                        </StyledTitle>
                        <StyledBody>
                            Changes in spot names will impact past and future reports associated with this layout and any
                            changes in spot positions have the potential to impact a customer’s upcoming reservation.
                        </StyledBody>
                        <StyledFooter>
                            <Button level='secondary' onClick={toggleModal}>Cancel</Button>
                            <Button 
                                disabled={ isLoading === true }
                                onClick={modalAction}
                            >
                                { isLoading ? 'Saving...' : 'Save Anyway' }
                            </Button>
                        </StyledFooter>
                    </Modal>
                </>
            )}
        </>
    );
};

export default ConfirmSaveModal;
