import { calculateSidePanelWidth } from "../components/SidePanel";
import { topnavHeight } from "../components/TopNav";
import type { AppProps, SetAppProps } from "../types/AppProps";
import Spot, { SPOT_HEIGHT, SPOT_WIDTH } from "../types/Spot";
import alignToGrid from "../utils/alignToGrid";
import autoSpotNumber from "../utils/autoSpotNumber";
import bbContainsBB from "../utils/bbContainsBB";
import bbContainsPoint from "../utils/bbContainsPoint";
import bbIntersect from "../utils/bbIntersect";
import { getSpotById } from "../utils/getSpotById";
import getSpotTypeByNameAndPriority from "../utils/getSpotTypeByNameAndPriority";
import isSpotOverTrash from "../utils/isSpotOverTrash";
import layoutBB from "../utils/layoutBB";
import log from "../utils/log";
import screenToCanvas from "../utils/screenToCanvas";
import trashSelectedSpots from "../utils/trashSelectedSpots";
import trashSingleSpot from "../utils/trashSingleSpot";
import updateSpotLocations from "../utils/updateSpotLocations";

/**
 * Both `onMouseUp` and `onTouchEnd` use the following code to:
 * - trash spots (when cursor is over trash)
 * - add a new spot (when `isAddingSpot` = true)
 * - update the location of spots (when finishing dragging)
 */
export default function abstractEnd(
  {
    reset,
    trash,
    ...props
  }: AppProps &
    SetAppProps & {
      reset: (hard?: boolean) => void;
      trash: React.RefObject<HTMLButtonElement>;
    },
  maybeSpotId: string
) {
  const {
    cursor,
    cursorDown,
    isAddingSpot,
    isDragging,
    isEdit,
    isNetting,
    isValidDraggingLocation,
    selected,
    setNetSelected,
    setSelected,
    setSpots,
    singleSpot,
    spots,
    spotTypes,
    zoom,
  } = props;

  const cursorOverTrash =
    trash.current && cursor
      ? bbContainsPoint(trash.current.getBoundingClientRect(), cursor)
      : false;

  // 1. Trashing spot(s)
  if (cursorOverTrash) {
    if (selected.length > 0) {
      trashSelectedSpots(props);
    } else if (singleSpot !== null) {
      trashSingleSpot(props);
    }
    setSelected([]);
    setNetSelected([]);
    return reset(false);
  }
  // 2. Adding a new spot
  else if (isAddingSpot) {
    const spot = {
      x: cursor.x - SPOT_WIDTH / 2,
      y: cursor.y - SPOT_HEIGHT / 2,
      width: SPOT_WIDTH,
      height: SPOT_HEIGHT,
    };
    const spotCanvas = {
      ...screenToCanvas(spot, props),
      width: SPOT_WIDTH,
      height: SPOT_HEIGHT,
    };
    const isWithinLayout = bbContainsBB(layoutBB(isEdit), spot);
    const isOverTrash = isSpotOverTrash(spot, { trash, ...props }, false);
    const isOverOtherSpots = spots.some(s => bbIntersect(s, spotCanvas));

    if (isWithinLayout && !isOverTrash && !isOverOtherSpots) {
      const cursorCanvas = screenToCanvas(
        {
          x: cursor.x - (zoom * SPOT_WIDTH) / 2,
          y: cursor.y - (zoom * SPOT_HEIGHT) / 2,
        },
        props
      );
      const spotLocation = alignToGrid(cursorCanvas);
      const newSpot = new Spot(spotLocation.x, spotLocation.y);
      newSpot.name = autoSpotNumber(spots);
      newSpot.type = getSpotTypeByNameAndPriority(
        spotTypes,
        isAddingSpot.name,
        isAddingSpot.priority
      );
      log(`Adding new spot (${newSpot.name})`);
      setSpots(spots.concat(newSpot));
    }
  }
  // 3. If dragging to a valid location,
  //    update the spot or spots
  else if (isDragging && isValidDraggingLocation) {
    // safety check -- do not drag spot or spots
    // outside of the valid layout zone
    const isWithinLayout = bbContainsPoint(layoutBB(isEdit), cursor);
    if (!isWithinLayout) return reset(false);
    if (selected.length > 0) {
      log(
        `Updating a group of spots (${selected
          .map(id => getSpotById(spots, id).name)
          .join(", ")})`
      );
      setSpots(
        updateSpotLocations(
          spots,
          (s: Spot) => selected.includes(s.id),
          cursorDown,
          cursor,
          zoom
        )
      );
    } else if (singleSpot !== null) {
      /**
       * If we're dragging a single spot, do not change its
       * state (selected or not), but do update its location
       */
      log(`Updating a single spot (${getSpotById(spots, singleSpot).name})`);
      const theSpots = updateSpotLocations(
        spots,
        (s: Spot) => s.id === singleSpot,
        cursorDown,
        cursor,
        zoom
      );
      setSpots(theSpots);
    }
  }
  // 4. If not dragging or netting any spots, and the mouse up
  //    happened within a spot, then must be clicking on this one --
  //    add it to or remove it from the selected
  else if (maybeSpotId !== null && !isDragging && !isNetting) {
    const newSelected = selected.includes(maybeSpotId)
      ? selected.filter(id => maybeSpotId !== id)
      : selected.concat(maybeSpotId);
    setSelected(newSelected);

    // do not hard reset
    return reset(false);
  }

  reset();
}
