import * as React from "react";
import * as ReactDOM from "react-dom";

/**
 * Higher-order component that creates a component at the location
 * specified by `id` (this works both in the browser and for automated testing, unlike
 * ReactDOM.createPortal out-of-the-box).
 * @param Component
 * @param id
 */
export default function withPortal<Props>(
  Component: (props: Props) => JSX.Element,
  id: string
) {
  let portalRoot = document.getElementById(id);
  if (portalRoot === null) {
    portalRoot = document.createElement("div");
    portalRoot.setAttribute("id", id);
    document.body.appendChild(portalRoot);
  }

  return (props: Props) => {
    return ReactDOM.createPortal(<Component {...props} />, portalRoot);
  };
}
