import type { AppProps, SetAppProps } from "../types/AppProps";
import log from "./log";
import { getSpotById } from "./getSpotById";

export default function trashSingleSpot({
  setSpots,
  setToast,
  setToastDuration,
  singleSpot,
  spots,
}: AppProps & SetAppProps): void {
  const theSingleSpot = getSpotById(spots, singleSpot);
  log(`Deleting spot (${theSingleSpot.name})`);
  setToastDuration(2);
  setToast("Spot Deleted");
  setSpots(spots.filter(s => s.id !== singleSpot));
}
