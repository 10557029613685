import type Point from "../types/Point";
import canvasCenter from "../utils/canvasCenter";

/**
 * Convert a point in canvas space to screen space. This is closely tied
 * to window dimensions and layout (e.g. `sidePanelWidth`) -- it assumes that
 * we always zoom in and out from the center of the canvas.
 * @param point
 * @param props
 */
export default function canvasToScreen(
  point: Point,
  props: { origin: Point; zoom: number }
): Point {
  const { origin, zoom } = props;

  const center = canvasCenter();

  const screenPoint = { ...point };

  screenPoint.x -= center.x;
  screenPoint.y -= center.y;

  screenPoint.x *= zoom;
  screenPoint.y *= zoom;

  screenPoint.x += origin.x * zoom;
  screenPoint.y += origin.y * zoom;

  screenPoint.x += center.x;
  screenPoint.y += center.y;

  return screenPoint;
}
