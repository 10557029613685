import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    *,
    *:before,
    *:after {
        box-sizing: border-box;
        position: relative;
    }

    html, 
    body {
        margin: 0;
        padding: 0;
    }

    body {
        background: #fafbfc;
        color: var(--color-text);
        font-family: var(--font-family);
        font-size: 16px;
        margin: 0;
        overflow: hidden;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: normal;
    }

    h1 {}
    h2 { font-size: 36px; }
    h3 { font-size: 24px; }
    h4 {}

    a {
        color: inherit;
    }

    :root {

        --color-grey-1: #f8f8f8;
        --color-grey-2: #E4E6E9;
        --color-grey-3: #C8CACC;
        --color-grey-4: #6D7073;
        --color-grey-5: #4F5052;
        --color-grey-6: #1C1C1E;
      
        --color-text: #31393B;
        --color-text-secondary: #757475;
        
        --color-primary: #008888;
        --color-primary-dark: #00656B;
        --color-primary-light: #00CEC7;
        --color-primary-extralight: #EBFCFB;
        
        --color-nav: #422F4D;
      
        --color-UIpositive: #03A67D;
        --color-UIpositive-dark: #038162;
        --color-UIpositive-light: #64BDA7;
        --color-UIpositive-extralight: #DEEEE5;
      
        --color-UInegative: #C5000C;
        --color-UInegative-dark: #AB000A;
        --color-UInegative-light: #DE000D;
        --color-UInegative-extralight: #FFEBEC;
      
        --color-UIwarning: #FAB505;
        --color-UIwarning-dark: #B58304;
        --color-UIwarning-light: #FFD05C;
        --color-UIwarning-extralight: #FFF9E8;
      
        --color-UIfrozen: #0087C2;
        --color-UIfrozen-dark: #006C9B;
        --color-UIfrozen-light: #2DB7F3;
        --color-UIfrozen-extralight: #EBF9FF;
        
        --spacing-xxs: 4px;
        --spacing-xs: 8px;
        --spacing-sm: 16px;
        --spacing-md: 24px;
        --spacing-lg: 32px;
        --spacing-xl: 48px;
        --spacing-xxl: 64px;
        --spacing-xxxl: 72px;

        --font-family: Avenir, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif;
    }

    .fill {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .h100 {
        height: 100%;
    }

    .w100 {
        width: 100%;
    }

    .pAbs {
        position: absolute;
    }
`;
