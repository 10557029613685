import type { AppProps } from "../types/AppProps";
import Spot, { SPOT_HEIGHT, SPOT_WIDTH } from "../types/Spot";
import type Point from "../types/Point";
import bbIntersect from "./bbIntersect";
import canvasToScreen from "./canvasToScreen";

// Detect if a single spot is over the trash by comparing
// its screen location to the trash's location.
export default function isSpotOverTrash(
  s: Point,
  props: AppProps & { trash: React.RefObject<HTMLButtonElement> },
  isCanvasSpace = true
): boolean {
  const { cursor, cursorDown, trash, zoom } = props;
  if (!trash || !trash.current) return false;

  // If in canvas space, then we need to normalize it to screen space
  // and add the cursor offset.
  // If not, we assume that the given x/y position is already in screen space
  // (but still apply the zoom adjustment below).
  const spotOnScreen = isCanvasSpace ? canvasToScreen(s, props) : s;
  if (isCanvasSpace && cursor && cursorDown) {
    spotOnScreen.x += cursor.x - cursorDown.x;
    spotOnScreen.y += cursor.y - cursorDown.y;
  }

  return bbIntersect(trash.current.getBoundingClientRect(), {
    ...spotOnScreen,
    width: SPOT_WIDTH * zoom,
    height: SPOT_HEIGHT * zoom,
  });
}
