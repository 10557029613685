// handles generation of code challenge and verifier pairs
import pkceChallenge from "pkce-challenge";

// handles formatting URLs and requests related to the Authorization Code Flow
// it also accepts extra arguments which help us enable PKCE
import ClientOAuth2 from "client-oauth2";

import store from "store";
import log from "./utils/log";
import ApiSpot from "./types/ApiSpot";

// these are global names for storage utilized by a variety of functions
const AUTH_STATE_COOKIE_NAME = "mariana-auth-state";
const AUTH_CODE_VERIFIER_COOKIE_NAME = "mariana-auth-code-verifier";
const AUTH_SESSION_NAME = "mariana-auth-session";

export const getTenentId = () => {
  const { hostname } = window.location;
  const envTenant = process.env.TENANT_ID;

  if (hostname === "localhost" || hostname.indexOf("192.168") === 0) {
    return envTenant;
  }

  const arr = hostname.split(".");

  if (arr.length > 2 && arr[arr.length - 2] === "herokuapp") {
    return envTenant;
  }

  // if not local or on Heroku, then return the dynamic part of the subdomain
  return arr.slice(0, -3).join(".");
};

const TENANT_ID: string = getTenentId();
log(`Tenant ID: ${TENANT_ID}`);

const TENANT_URL = process.env.API_URL || `https://${TENANT_ID}.marianatek.com`;

const marianaAuth = new ClientOAuth2({
  // TODO: this will need to come from Heroku env var (I think)
  clientId: process.env.CLIENT_ID || "p83HKp2XQ75KFTUVDrP66ACu7fLsMHTM0PrsvIP1",
  accessTokenUri: TENANT_URL + "/o/token/",
  authorizationUri: TENANT_URL + "/o/authorize",
  redirectUri: window.location.origin + "/auth",
  scopes: ["read:account"],
});

let IS_SANDBOX = false;

export function sandbox() {
  IS_SANDBOX = true;
}

export function isSandbox() {
  return IS_SANDBOX;
}

export async function apiFetch(endpoint: string) {
  // add the access token to the `Authorization` header to make requests
  const { access_token } = store.get(AUTH_SESSION_NAME);
  const res = await fetch(TENANT_URL + endpoint, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  const { data } = await res.json();
  return data;
}

export async function apiPost(endpoint: string, data: any, method = 'POST') {
  // add the access token to the `Authorization` header to make requests
  const { access_token } = store.get(AUTH_SESSION_NAME);
  const formData = new FormData();
  for (let key in data) {
    formData.set(key, data[key]);
  }
  const res = await fetch(TENANT_URL + endpoint, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    method,
    body: JSON.stringify(data),
  });

  const json = await res.json();

  if ([500, 401, 403, 422].includes(res.status)) {
    return {
      ...json,
      status: res.status,
    };
  }

  return json;
}

/**
 * this function handles clicking the login button. it initializes the state,
 * code challenge, and accompanying cookies as well as redirecting to the auth
 */
export function login(redirectUri = null) {
  const { code_challenge, code_verifier } = pkceChallenge();

  // we're hijacking the `pkceChallenge` package here to generate a random
  // string, any random string that can be easily sanitized for a URI will
  // do
  const { code_challenge: stateString } = pkceChallenge();

  store.set(
    AUTH_STATE_COOKIE_NAME + stateString,
    redirectUri
      ? {
          redirectUri,
        }
      : {}
  );
  store.set(AUTH_CODE_VERIFIER_COOKIE_NAME, code_verifier);

  const authUri = marianaAuth.code.getUri({
    state: stateString,

    // these extra values power the PKCE part of the flow
    query: {
      code_challenge: code_challenge,
      code_challenge_method: "S256",
    },
  });

  window.location.assign(authUri);
}

/**
 * logging out is simple, just remove the stored session
 */
export function logout() {
  store.remove(AUTH_SESSION_NAME);
}

/**
 * this function handles authentication by requesting a token, clearing the
 * remaining challenge cookies, and storing our session data
 *
 * @param {URL} url
 * @param {string} code
 */
async function authenticate(url: URL, code: string) {
  // uses `client-oauth2`'s `getToken` method, passing in extra PKCE related values
  // to the `body` key
  const token = await marianaAuth.code.getToken(url, {
    body: {
      grant_type: "authorization_code",
      code,
      code_verifier: store.get(AUTH_CODE_VERIFIER_COOKIE_NAME),
    },
  });

  log(`Authenticating ${url} with code ${code}`);

  // removing the code verifier so it can not be used again
  store.remove(AUTH_CODE_VERIFIER_COOKIE_NAME);

  // convert the `expires` date object value to unix timestamp so it can be easily stored
  // and merge it with the rest of the token data
  const sessionData = {
    ...token.data,
    // @ts-ignore
    expired_at: token.expires.getTime(),
  };

  // store session data
  store.set(AUTH_SESSION_NAME, sessionData);
}

/**
 * simple function to check authenticated state. if you are using a SPA
 * framework there should be a package to handle this for you.
 */
export function isAuthenticated() {
  if (isSandbox()) return true;
  const sessionData = store.get(AUTH_SESSION_NAME);
  return !!sessionData && !!sessionData.access_token;
}

export async function getClassroom(
  id: string
): Promise<{
  attributes: {
    name: string;
  };
  relationships: {
    location: {
      data: {
        id: string;
      };
    };
  };
}> {
  if (!isAuthenticated()) return null;
  return await apiFetch("/api/classrooms/" + id);
}

export async function getLocation(
  id: string
): Promise<{
  attributes: {
    name: string;
  };
}> {
  if (!isAuthenticated()) return null;
  return await apiFetch("/api/locations/" + id);
}

export async function getLayouts() {
  if (!isAuthenticated()) return null;
  return await apiFetch("/api/layouts");
}

export async function getLayout(
  id: string
): Promise<{
  classroom: {
    id: string;
    name: string;
  };
  name: string;
  spots: ApiSpot[];
}> {
  if (!isAuthenticated()) return null;
  // add the access token to the `Authorization` header to make requests
  const { access_token } = store.get(AUTH_SESSION_NAME);
  const res = await fetch(TENANT_URL + "/api/studio/v1/layouts/" + id, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  const data = await res.json();
  return data;
}

export async function getSpots() {
  if (!isAuthenticated()) return null;
  return await apiFetch("/api/spots");
}

export async function getSpotTypes(): Promise<
  {
    id: string;
    attributes: { name: string; priority: "primary" | "secondary" };
  }[]
> {
  if (!isAuthenticated()) return null;
  return await apiFetch("/api/spot_types");
}

/**
 * This section contains what would align with the "initialization" step of
 * your components.
 *
 * In React, think the `componentDidMount` step or a function passed
 * as the initial state of a `useState` hook.
 *
 * `mounted` in Vue, `init` in Ember...
 *
 * There are three distinct sections:
 *
 * 1. setting up interaction
 * 2. checking whether we are currently attempting to authenticate
 * 3. checking whether we are currently authenticated
 *
 * In a more typical SPA this would be broken up by component or route.
 */

export interface AuthObj {
  isAuthenticated: boolean;
  redirectUri?: string;
}

export async function checkAuthentication(): Promise<AuthObj> {
  log("Checking user authentication...");

  // 2. Are we attempting to authenticate?
  // this section establishes whether we are currently authenticating
  const url = new URL(location.href);
  const code = url.searchParams.get("code");
  const state = url.searchParams.get("state");
  const stateKey = AUTH_STATE_COOKIE_NAME + state;
  const localState = store.get(stateKey);
  let redirectUri: string;

  // is code present AND is state present AND does state match?
  const isAuthenticating = code && state && localState;

  // remove so state can not be used again
  store.remove(stateKey);

  if (isAuthenticating) {
    redirectUri = localState.redirectUri;
    // if we're authenticating run the authenticate function...
    await authenticate(url, code);
  }

  log(`User is${isAuthenticated() ? "" : " not"} authenticated`);

  return {
    isAuthenticated: isAuthenticated(),
    redirectUri,
  };
}
