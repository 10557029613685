import { cloneDeep } from "lodash";
import Point from "../types/Point";
import Spot, { SPOT_UNIT } from "../types/Spot";

/**
 * Given a list of spots, update their locations.
 * Only those that pass the given filter function will be updated.
 * Cursor/cursor positions say how/where the spots should be moved.
 * Note that this returns an entirely new array of (cloned) spots.
 * Updating state needs to be handled elsewhere.
 * @param spots
 * @param filter
 * @param cursorDown
 * @param cursor
 */
export default function updateSpotLocations(
  spots: Spot[],
  filter: (s: Spot) => boolean,
  cursorDown: Point,
  cursor: Point,
  zoom: number
): Spot[] {
  if (!cursorDown || !cursor) return spots;
  return cloneDeep(spots).map((spot: Spot, i: number) => {
    // since `cloneDeep` creates entirely new objects,
    // we need to reference the original spot in the `spots` array
    // (rather than the one being mapped over)...
    // however, we do mutate and return the one being mapped over
    if (filter(spots[i])) {
      spot.x +=
        Math.round((cursor.x - cursorDown.x) / zoom / SPOT_UNIT) * SPOT_UNIT;
      spot.y +=
        Math.round((cursor.y - cursorDown.y) / zoom / SPOT_UNIT) * SPOT_UNIT;
    }
    return spot;
  });
}
