const KEYS_DOWN: string[] = [];

export const keyDown = (key: string) => {
  if (!KEYS_DOWN.includes(key)) KEYS_DOWN.push(key);
};

export const keyUp = (key: string) => {
  if (!KEYS_DOWN.includes(key)) return;
  KEYS_DOWN.splice(KEYS_DOWN.indexOf(key), 1);
};

export default KEYS_DOWN;
