import * as React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { topnavHeight } from "./TopNav";
import type { AppProps, SetAppProps } from "../types/AppProps";
import caret from "../img/caret-down.svg";
import AddingSpotNode from "../atoms/AddingSpotNode";
import Toggle from "../atoms/Toggle";
import { SPOT_HEIGHT, SPOT_WIDTH } from "../types/Spot";
import isSpotOverTrash from "../utils/isSpotOverTrash";
import screenToCanvas from "../utils/screenToCanvas";
import bbIntersect from "../utils/bbIntersect";
import { BREAKPOINT_M, BREAKPOINT_S } from "../constants";
import { cloneDeep } from "lodash";
import { getSpotsById } from "../utils/getSpotById";
import type SpotType from "../types/SpotType";
import { spotWithBuffer } from "../utils/determineValidSpotLocation";
import getSpotTypeByNameAndPriority from "../utils/getSpotTypeByNameAndPriority";
import AddSpotGroup from "../atoms/AddSpotGroup";
import bbContainsPoint from "../utils/bbContainsPoint";
import layoutBB from "../utils/layoutBB";

export const sidePanelWidth = 350;
export const sidePanelWidthMed = 275;

export const calculateSidePanelWidth = (): number => {
  if (window.innerWidth < BREAKPOINT_M) return sidePanelWidthMed;
  return sidePanelWidth;
};

const StyledSidePanel = styled.div`
  background: #fff;
  height: 100%;
  overflow-y: scroll;
  right: 0;
  padding: ${topnavHeight + 48}px 48px 48px;
  position: absolute;
  top: 0;
  width: ${sidePanelWidth}px;

  h3 {
    margin: 0 0 24px;
  }

  h4 {
    border-bottom: 1px solid var(--color-grey-3);
    font-weight: bold;
    padding-bottom: 12px;
  }

  h5 {
    font-size: 14px;
    font-weight: bold;
    margin: 16px 0 12px;
  }

  @media screen and (max-width: ${BREAKPOINT_M}px) {
    padding: ${topnavHeight + 32}px 32px 32px;
    width: ${sidePanelWidthMed}px;
  }

  @media screen and (max-width: ${BREAKPOINT_S}px) {
    padding: ${topnavHeight + 24}px 24px 24px;
  }
`;

const StyledSelect = styled.select`
  appearance: none;
  background-color: #fff;
  background-image: url(${caret});
  background-position: calc(100% - 8px) center;
  background-repeat: no-repeat;
  border: 1px solid var(--color-grey-3);
  border-radius: 3px;
  font-family: var(--font-family);
  font-size: 16px;
  padding: 8px 12px;
  width: 100%;
`;

const EditSpotOption = ({
  spotType,
  ...props
}: {
  spotType: SpotType;
} & React.OptionHTMLAttributes<HTMLOptionElement>) => {
  const { selected } = props;
  return (
    <option selected={selected} value={spotType.name + "|" + spotType.priority}>
      {spotType.name}
      {spotType.priority === "secondary" && " (Secondary)"}
    </option>
  );
};

const EditSpotGroup = ({
  selected,
  setSpots,
  setToast,
  setToastDuration,
  spots,
  spotTypes,
}: AppProps &
  SetAppProps & {
    spotTypes: SpotType[];
  }) => {

  const selectedSpots = getSpotsById(spots, selected);
  if (selectedSpots.length === 0) return null;

  let isMixed = false;
  let numAvailable = selectedSpots[0].available ? 1 : 0;
  for (let i = 1; i < selectedSpots.length; i++) {
    if (!isMixed) {
      isMixed = selectedSpots[i].type !== selectedSpots[0].type;
    }
    numAvailable += selectedSpots[i].available ? 1 : 0;
  }

  const firstType = selectedSpots[0].type;
  const value = !isMixed ? firstType.name + "|" + firstType.priority : "";

  return (
    <div>
      <h4>Edit Spot{selected.length > 1 ? "s" : ""}</h4>
      <h5>Spot Type</h5>
      <StyledSelect
        value={value}
        onChange={e => {
          const { value } = e.currentTarget;
          if (!value) return;
          // do nothing if not actually changing the type
          if (!isMixed && value === firstType.name + "|" + firstType.priority)
            return;
          const newSpots = cloneDeep(spots);
          newSpots.forEach(spot => {
            if (!selected.includes(spot.id)) return;
            const [name, priority] = value.split("|");
            if (priority !== "primary" && priority !== "secondary") return;
            spot.type = getSpotTypeByNameAndPriority(spotTypes, name, priority);
          });
          setSpots(newSpots);
          setToast(`Spot Type${selected.length > 1 ? "s" : ""} Updated`);
          setToastDuration(2);
        }}
        onKeyDown={e => e.preventDefault()}
      >
        {isMixed && <option>Mixed</option>}
        {spotTypes.map(type => (
          <EditSpotOption
            key={type.name + "|" + type.priority}
            spotType={type}
          />
        ))}
      </StyledSelect>
      <h5>Spot Availability</h5>
      <label htmlFor="spot-availability-toggle">
        <Toggle
          active={numAvailable > 0}
          id="spot-availability-toggle"
          onClick={e => {
            if (e.buttons === 2) return;
            const newSpots = cloneDeep(spots);
            newSpots.forEach(spot => {
              if (!selected.includes(spot.id)) return;
              spot.available = numAvailable === 0;
            });
            setSpots(newSpots);
          }}
        />
        {numAvailable > 0 ? "Available" : "Unavailable"}
      </label>
    </div>
  );
};

const SidePanel = ({
  layout,
  trash,
  ...props
}: AppProps &
  SetAppProps & {
    layout: React.RefObject<HTMLDivElement>;
    trash: React.RefObject<HTMLButtonElement>;
  }) => {
  const { cursor, isAddingSpot, selected, spots, spotTypes } = props;

  const { search } = useLocation();
  const isEdit = search?.split('=')[1] === 'edit';
  
  let isWithinLayout = false;
  let isOverTrash = false;
  let isOverOtherSpots = false;

  if (cursor && isAddingSpot) {
    const spot = {
      x: cursor.x - SPOT_WIDTH / 2,
      y: cursor.y - SPOT_HEIGHT / 2,
      width: SPOT_WIDTH,
      height: SPOT_HEIGHT,
    };

    const spotCanvas = {
      ...screenToCanvas(spot, props),
      width: SPOT_WIDTH,
      height: SPOT_HEIGHT,
    };

    isWithinLayout = bbContainsPoint(layoutBB(), cursor);
    isOverTrash = isSpotOverTrash(spot, { trash, ...props }, false);
    isOverOtherSpots = spots.some(s =>
      bbIntersect(spotWithBuffer(s), spotCanvas)
    );
  }

  return (
    <> 
      {
        !isEdit && (
          <StyledSidePanel data-testid="SidePanel">
          <h3>Configure Layout</h3>
          {selected.length > 0 ? (
            <EditSpotGroup {...props} spotTypes={spotTypes} />
          ) : (
            <div>
              <h4>Add Spots</h4>
              <div>
                {spotTypes.map(spotType => {
                  return (
                    <AddSpotGroup
                      {...props}
                      key={spotType.name + "|" + spotType.priority}
                      spotType={spotType}
                    />
                  );
                })}
              </div>
              {isAddingSpot && (
                <AddingSpotNode
                  {...props}
                  invalid={isOverTrash || isOverOtherSpots}
                  showShadow={isWithinLayout && !isOverTrash}
                />
              )}
            </div>
          )}
        </StyledSidePanel>
        )

      }
      
    </>
  );
};

export default SidePanel;
