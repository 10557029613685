import styled from "styled-components";

const StyledTooltip = styled.div`
  background: #fff;
  border-radius: 4px;
  bottom: 32px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
  color: var(--color-text-secondary);
  font-size: 12px;
  left: 0;
  padding: 16px;
  pointer-events: none;
  position: absolute;
  width: 260px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--color-text);
  }
`;

export default StyledTooltip;
