import Spot from "../types/Spot";

export default function isSpotSingular(
  spot: Spot,
  { selected, singleSpot }: { selected: string[]; singleSpot: string }
) {
  const isSelected = selected.includes(spot.id);
  const isSingular = spot.id === singleSpot && !isSelected;
  return isSingular;
}
