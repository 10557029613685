import * as React from "react";
import type { AppProps, SetAppProps } from "../types/AppProps";
import { SPOT_HEIGHT, SPOT_WIDTH } from "../types/Spot";
import alignToGrid from "../utils/alignToGrid";
import autoSpotNumber from "../utils/autoSpotNumber";
import screenToCanvas from "../utils/screenToCanvas";
import withPortal from "../utils/withPortal";
import { StyledInput } from "./SpotNode";
import StyledSpot from "./StyledSpot";
import SpotShadow from "./SpotShadow";
import SecondaryIcon from "./SecondaryIcon";
import SpotNodeType from "./SpotNodeType";
import layoutBB from "../utils/layoutBB";
import bbContainsPoint from "../utils/bbContainsPoint";

let SpotShadowPortal: ({
  invalid,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  invalid: boolean;
}) => React.ReactPortal = null;

const AddingSpotNode = (
  props: AppProps &
    SetAppProps &
    React.ButtonHTMLAttributes<HTMLButtonElement> & {
      invalid: boolean;
      showShadow: boolean;
    }
) => {
  const { isAddingSpot, cursor, invalid, showShadow, spots, zoom } = props;

  if (cursor === null) return null;

  const cursorCanvas = screenToCanvas(
    {
      x: cursor.x - (zoom * SPOT_WIDTH) / 2,
      y: cursor.y - (zoom * SPOT_HEIGHT) / 2,
    },
    props
  );
  const shadow = alignToGrid(cursorCanvas);

  if (SpotShadowPortal === null) {
    SpotShadowPortal = withPortal(
      ({
        invalid,
        ...props
      }: React.HTMLAttributes<HTMLDivElement> & {
        invalid: boolean;
      }) => {
        return <SpotShadow invalid={invalid} {...props} />;
      },
      "adding-spot-shadow"
    );
  }

  const style: React.CSSProperties = {
    left: 0,
    position: "fixed",
    top: 0,
    transform: `translateX(${cursor.x - SPOT_WIDTH / 2}px) translateY(${
      cursor.y - SPOT_HEIGHT / 2
    }px) rotate(5deg) scale(${zoom})`,
    transformOrigin: "center center",
  };

  if (!bbContainsPoint(layoutBB(), cursor)) style.borderColor = "#1c1c1e";

  return (
    <>
      {showShadow && (
        <SpotShadowPortal
          invalid={invalid}
          style={{
            left: 0,
            position: "fixed",
            top: 0,
            transform: `translateX(${shadow.x}px) translateY(${shadow.y}px)`,
          }}
        />
      )}
      <StyledSpot
        available={true}
        cursorOverTrash={false}
        interactive={true}
        invalid={invalid}
        shouldOffset={true}
        style={style}
      >
        {isAddingSpot.priority === "secondary" && <SecondaryIcon type="spot" />}
        <StyledInput
          defaultValue={autoSpotNumber(spots)}
          disabled
          isNetting={true}
        />
        <SpotNodeType zoom={zoom}>{isAddingSpot.name}</SpotNodeType>
      </StyledSpot>
    </>
  );
};

export default AddingSpotNode;
