import * as React from "react";
import styled, { css } from "styled-components";
import { AppProps, SetAppProps } from "../types/AppProps";

const StyledBackground = styled.div<{
  isPanning: boolean;
  readyToPan: boolean;
}>`
  height: 9999px;
  left: 0;
  position: fixed;
  top: 0;
  touch-action: none;
  transform: translateX(-50%) translateY(-50%);
  width: 9999px;

  ${props =>
    props.readyToPan &&
    css`
      cursor: grab;
    `}

  ${props =>
    props.isPanning &&
    css`
      cursor: grabbing;
    `}
`;

const Background = ({
  keysDown,
  isPanning,
  setCursorDown,
  setIsNetting,
  setIsPanning,
  setSelected,
}: AppProps & SetAppProps) => {
  return (
    <StyledBackground
      isPanning={isPanning}
      onMouseDown={e => {
        // e.button = 2 means that the user clicked the right
        // mouse button to open a context menu -- in that case
        // don't set cursorDown, etc.
        if (e.button === 2) return;
        e.stopPropagation();
        setCursorDown({ x: e.pageX, y: e.pageY });
        if (keysDown.includes(" ")) {
          setIsPanning(true);
        } else {
          setIsNetting(true);
          if (!e.shiftKey) setSelected([]);
        }
      }}
      onTouchStart={e => {
        if (e.touches.length === 1) {
          setIsNetting(true);
        }
      }}
      readyToPan={keysDown.includes(" ")}
    />
  );
};

export default Background;
