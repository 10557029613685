import { XProps } from "../types/zoid";

// This ensures Jest doesn't try to test this function.
// We're using noop (or no-operation) to cover the case if no setNotifications is passed
// since its optional, but we don't need to test it.
// Also, its broken out into its own constant so we can use the flag below easily and cleanly
/* istanbul ignore next */
const noop = (): void => {};

export type XPropEvents = Pick<XProps,
  'onDisplay'
| 'onRendered'
| 'onRender'
| 'onClose'
| 'onResize'
| 'onFocus'
| 'onError'
| 'onProps'
>;

const xpropEventFallbacks = (): XPropEvents => ({
    onDisplay: noop,
    onRendered: noop,
    onRender: noop,
    onClose: noop,
    onResize: noop,
    onFocus: noop,
    onError: noop,
    onProps: noop,
});

const xpropFallbacks = (): XProps => ({
    ...xpropEventFallbacks(),
    classroomId: '',
    initialRoute: '',
    layoutId: '',
    onSaveLayout: async (classroom: string) => {},
});

const getXprops = (): XProps => Object.assign({}, xpropFallbacks(), window.xprops);

const useXProps = (): XProps => getXprops();

export { xpropEventFallbacks, xpropFallbacks, getXprops, useXProps };
