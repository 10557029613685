import { concat, uniq } from "lodash";

/**
 * Take two arrays and concatenate them, removing any duplicate items.
 * @param arr1
 * @param arr2
 */
export default function concatUniq<T>(arr1: T[], arr2: T[]): T[] {
  return uniq(concat(arr1, arr2));
}
