import type { AppProps, SetAppProps } from "../types/AppProps";
import { getSpotById } from "./getSpotById";
import log from "./log";

export default function trashSelectedSpots({
  netSelected,
  selected,
  setSpots,
  setToast,
  setToastDuration,
  spots,
}: AppProps & SetAppProps): void {
  const combinedSelected = selected.concat(netSelected);
  if (combinedSelected.length === 0) return;
  log(
    `Deleting spots (${combinedSelected
      .map(id => getSpotById(spots, id).name)
      .join(", ")})`
  );
  setToastDuration(2);
  setToast(
    `${combinedSelected.length} Spot${
      combinedSelected.length > 1 ? "s" : ""
    } Deleted`
  );
  setSpots(spots.filter(s => !combinedSelected.includes(s.id)));
}
