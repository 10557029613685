import * as React from "react";
import { useParams } from "react-router-dom";
import { getLayout } from "../auth";
import { useXProps } from "../hooks/useXprops";
import type { AppProps, SetAppProps } from "../types/AppProps";
import boundingBoxOfSpots from "../utils/bbOfSpots";
import { percentDecode } from "../utils/percentEncodingHack";
import { unmapSpots } from "../utils/saveLayoutToApi";
import { saveClassroomID, saveLayoutData } from "../utils/store";
import { calculateSidePanelWidth } from "./SidePanel";

const Clone = (props: AppProps & SetAppProps) => {
  const { setFlow, setName, setOrigin, setRedirect, setSpots } = props;
  let { layout } = useParams<{ layout: string }>();
  layout = percentDecode(layout);

  const { classroomId } = useXProps();

  React.useEffect(() => {
    (async () => {
      const { classroom, name, spots } = await getLayout(layout);
      saveClassroomID(classroomId || classroom.id);

      const copyName = "Copy of " + name;
      const unmappedSpots = unmapSpots(spots);
      setFlow(null);
      setName(copyName);

      const bb = boundingBoxOfSpots(unmappedSpots);
      const windowOffsetX = Math.round(
        (window.innerWidth - calculateSidePanelWidth() - bb.width) / 2
      );
      const windowOffsetY = Math.round((window.innerHeight - bb.height) / 2);
      const origin = { x: windowOffsetX, y: windowOffsetY };
      saveLayoutData(copyName, { spots: unmappedSpots, origin });

      setSpots(unmappedSpots);
      setOrigin(origin);
      setRedirect("/layout/" + encodeURIComponent(copyName));
    })();
  }, []);
  return <div />;
};

export default Clone;