import { AppProps } from "../types/AppProps";
import Point from "../types/Point";
import Spot from "../types/Spot";
import isSpotSingular from "./isSpotSingular";

export default function getSpotOffset(spot: Spot, props: AppProps): Point {
  const {
    cursor,
    cursorDown,
    isAddingSpot,
    isDragging,
    isNetting,
    selected,
    touchCount,
    zoom,
  } = props;

  if (isAddingSpot) return { x: 0, y: 0 };

  const isSelected = selected.includes(spot.id);
  const isSingular = isSpotSingular(spot, props);

  const shouldOffset =
    !isNetting &&
    ((isDragging && isSelected) ||
      (touchCount === 1 && isSelected) ||
      isSingular) &&
    cursor !== null &&
    cursorDown !== null;

  return {
    x: shouldOffset ? (cursor.x - cursorDown.x) / zoom : 0,
    y: shouldOffset ? (cursor.y - cursorDown.y) / zoom : 0,
  };
}
