import * as React from "react";
import styled from "styled-components";

const StyledButton = styled.button<{
  level: "primary" | "secondary";
  round: boolean;
}>`
  color: ${props => (props.level === "primary" ? "#fff" : "var(--color-text)")};
  cursor: pointer;
  background-color: ${props =>
    props.level === "primary" ? "var(--color-primary)" : "#fff"};
  border: 0 none;
  box-shadow: 0px 2px 6px transparent,
    inset 0 0 0 1px
      ${props =>
        props.level === "primary" ? "var(--color-primary)" : "#BABEC2"};
  border-radius: ${props => (props.round ? "50%" : "4px")};
  font-family: var(--font-family);
  font-size: 16px;
  padding: 10px 24px;
  transition: 0.2s opacity, 0.2s box-shadow;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08),
      inset 0 0 0 2px
        ${props =>
          props.level === "primary" ? "var(--color-primary-dark)" : "#BABEC2"};
  }

  &:focus {
    border-width: 2px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08),
      inset 0 0 0 2px
        ${props =>
          props.level === "primary" ? "var(--color-primary-dark)" : "#BABEC2"};
    outline: 0;
  }
`;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  level?: "primary" | "secondary";
  round?: boolean;
}

export default React.forwardRef(
  (
    { children, level = "primary", round = false, ...props }: ButtonProps,
    ref: any
  ) => {
    return (
      <StyledButton level={level} ref={ref} round={round} {...props}>
        {children}
      </StyledButton>
    );
  }
);
