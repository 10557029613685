import BoundingBox from "../types/BoundingBox";

export default function bbContainsBB(
  outer: BoundingBox,
  inner: BoundingBox
): boolean {
  if (!outer || !inner) return false;
  return (
    outer.x <= inner.x &&
    outer.y <= inner.y &&
    outer.x + outer.width >= inner.x + inner.width &&
    outer.y + outer.height >= inner.y + inner.height
  );
}
