import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import Spot, { SPOT_UNIT, SPOT_WIDTH, SPOT_HEIGHT } from "../types/Spot";
import SpotNode from "../atoms/SpotNode";
import SpotShadow from "../atoms/SpotShadow";
import BaseShape from "../types/BaseShape";
import type { AppProps, SetAppProps } from "../types/AppProps";
import ZoomControls from "../atoms/ZoomControls";
import boundingBoxOfSpots from "../utils/bbOfSpots";
import { topnavHeight } from "./TopNav";
import { getLayoutData } from "../utils/store";
import { calculateSidePanelWidth } from "./SidePanel";
import canvasToScreen from "../utils/canvasToScreen";
import GridPattern from "../atoms/GridPattern";
import Trash from "../atoms/Trash";
import alignToGrid from "../utils/alignToGrid";
import isSpotSingular from "../utils/isSpotSingular";
import getSpotOffset from "../utils/getSpotOffset";
import Fill from "../atoms/Fill";
import Background from "../atoms/Background";
import bbContainsPoint from "../utils/bbContainsPoint";
import KEYS_DOWN from "../utils/keysDown";

const Container = styled(Fill)<{ isZooming: boolean }>`
  touch-action: none;
  user-select: none;
  width: 100vw;
`;

export default (
  props: AppProps &
    SetAppProps & {
      layout: React.RefObject<HTMLDivElement>;
      reset: (hard?: boolean) => void;
      trash: React.RefObject<HTMLButtonElement>;
    }
) => {
  const {
    cursor,
    cursorSpot,
    isDragging,
    isNetting,
    isPanning,
    isValidDraggingLocation,
    layout,
    netSelected,
    origin,
    selected,
    setFlow,
    setName,
    setOrigin,
    setSelected,
    setSingleSpot,
    setSpots,
    setZoom,
    shape,
    singleSpot,
    spotCount,
    spots,
    spotTypes,
    trash,
    zoom,
  } = props;
  const { layout: layoutParam } = useParams<{ layout: string; }>();
  const [prevCursor, setPrevCursor] = React.useState(null);
  const containerRef = React.useRef<HTMLDivElement>();

  const [isZooming, setIsZooming] = React.useState(false);

  // Whenever the cursor updates, if we are panning,
  // then the origin should be updated.
  React.useEffect(() => {
    if (isPanning && cursor && prevCursor) {
      setOrigin({
        x: Math.round(origin.x + (cursor.x - prevCursor.x) / zoom),
        y: Math.round(origin.y + (cursor.y - prevCursor.y) / zoom),
      });
    }
    setPrevCursor(cursor);
  }, [cursor]);

  const { search } = useLocation();
  const isEdit = search?.split('=')[1] === 'edit';

  const generateNonUShapedLayout = (): Spot[] => {
    const theSpots = [];
    const columnsLookup = {
      [BaseShape.SQUARE]: Math.sqrt(spotCount),
      [BaseShape.WIDE_RECTANGLE]: Math.sqrt(2 * spotCount),
      [BaseShape.LONG_RECTANGLE]: Math.sqrt(spotCount / 2),
    };
    const columns = Math.ceil(columnsLookup[shape]);
    const rows = Math.ceil(spotCount / columns);
    let row = 0;
    let column = 0;
    let i = 1;
    let numInLastRow: number;
    while (theSpots.length < spotCount) {
      let x = column * (SPOT_WIDTH + SPOT_UNIT);
      if (row === rows - 1) {
        if (column === 0) numInLastRow = spotCount - theSpots.length;
        x =
          (column + 0.5 * (columns - numInLastRow)) * (SPOT_WIDTH + SPOT_UNIT);
      }
      const y = row * (SPOT_HEIGHT + SPOT_UNIT);
      const spot = new Spot(x, y);
      spot.name = (i++).toString();
      spot.type = spotTypes.length > 0 ? spotTypes[0] : null;
      theSpots.push(spot);
      if (theSpots.length % columns === 0) {
        column = 0;
        row++;
      } else {
        column++;
      }
    }
    return theSpots;
  };

  const generateUShapedLayout = (): Spot[] => {
    const theSpots = [];
    // For U-shape layouts, optimize
    // for a 3:2 column:row ratio
    const columns = Math.ceil(Math.sqrt((9 * spotCount) / 5));
    const rows = Math.ceil(Math.sqrt((4 * spotCount) / 5));
    const flank = ((columns + 1.5) / 3) | 0;
    const gapWidth = columns - flank * 2;
    let row = 0;
    let column = 0;
    let i = 1;
    let numInLastRow: number;
    while (theSpots.length < spotCount) {
      let x = column * (SPOT_WIDTH + SPOT_UNIT);
      if (row === rows - 1) {
        if (column === 0) numInLastRow = spotCount - theSpots.length;
        x =
          (column + 0.5 * (columns - numInLastRow)) * (SPOT_WIDTH + SPOT_UNIT);
      }
      const y = row * (SPOT_HEIGHT + SPOT_UNIT);
      const spot = new Spot(x, y);
      spot.name = (i++).toString();
      spot.type = spotTypes.length > 0 ? spotTypes[0] : null;
      theSpots.push(spot);
      if (column + 1 === columns) {
        column = 0;
        row++;
      } else if (column === flank - 1 && row < rows / 2) {
        column += gapWidth + 1;
      } else {
        column++;
      }
    }
    return theSpots;
  };

  const generateLayout = () => {
    const theSpots: Spot[] =
      shape !== BaseShape.U_SHAPE
        ? generateNonUShapedLayout()
        : generateUShapedLayout();
    setSpots(theSpots);

    const bb = boundingBoxOfSpots(theSpots);

    const windowOffsetX = Math.round(
      (window.innerWidth - calculateSidePanelWidth() - bb.width) / 2
    );
    const windowOffsetY = Math.round((window.innerHeight - bb.height) / 2);
    setOrigin({ x: windowOffsetX, y: windowOffsetY });

    let theZoom = zoom;
    while (
      bb.width * theZoom > window.innerWidth - calculateSidePanelWidth() ||
      bb.height * theZoom > window.innerHeight - 1.5 * topnavHeight
    ) {
      theZoom *= 0.95;
    }
    setZoom(theZoom);
  };

  /**
   * If creating this Layout, initialize it with spots.
   */
  React.useEffect(() => {
    const layoutData = getLayoutData(layoutParam);

    if (layoutData === null) {
      if (spots.length < spotCount) generateLayout();
    } else {
      setName(decodeURIComponent(layoutParam));
      setFlow(null);
      setSpots(layoutData.spots);
    }
  }, []);

  const center = canvasToScreen({ x: 0, y: 0 }, props);
  const containerTransform = `scale(${zoom}) translate(${origin.x}px, ${origin.y}px)`;
  const containerWidth = window.innerWidth - calculateSidePanelWidth();

  const cursorOverTrash =
    trash.current && cursor
      ? bbContainsPoint(trash.current.getBoundingClientRect(), cursor)
      : false;

  /**
   * The Layout is going to render everything on the canvas in a few passes...
   * Although this gets a little bit hairy, it's easier to keep track of everything
   * in actual order rather than playing with z-index too much.
   *
   * From bottom to top, there shall be:
   * 1. GridPattern background
   * 2. All the spot shadows
   * 3. The background that receives mouse/touch events
   * 3. All the spots that are *not* being dragged
   * 4. Trash
   * 5. Zoom Controls
   * 6. All the spots that *are* being dragged
   */
  return (
    <>
      <GridPattern
        style={{
          backgroundSize: `${SPOT_UNIT * 2 * zoom}px ${SPOT_UNIT * 2 * zoom}px`,
          backgroundPosition: `${Math.round(center.x)}px ${Math.round(
            center.y
          )}px`,
        }}
      />
      <Container
        data-testid="Layout"
        isZooming={isZooming}
        ref={containerRef}
        style={{
          transform: containerTransform,
          width: containerWidth,
        }}
      >
        {
          /**
           * 2. All the spot shadows
           */
          spots.map(spot => {
            if (cursorOverTrash) return null;
            const validLocation = !isValidDraggingLocation
              ? !isSpotSingular(spot, props) && !selected.includes(spot.id)
              : true;
            const offset = getSpotOffset(spot, props);
            return (
              <SpotShadow
                key={spot.id}
                invalid={!validLocation}
                style={{
                  left: spot.x,
                  top: spot.y,
                  transform: `translateX(${
                    alignToGrid(offset).x
                  }px) translateY(${alignToGrid(offset).y}px)`,
                }}
              />
            );
          })
        }
        {/**
         * 3. The background that receives mouse/touch events
         */}
        <Background {...props} />
        {
          /**
           * 4. All the spots that are not being dragged
           */
          spots.map(spot => {
            /**
             * Per https://stackoverflow.com/questions/24270204/touchmove-event-fired-only-once-when-child-is-removed...
             * Rendering `null` for SpotNodes when the touch event begins
             * on them causes touchmove to stop working -- therefore we
             * just want to hide these visually, rather than not
             * render them at all
             */
            let hidden = false;
            if (
              (isSpotSingular(spot, props) || selected.includes(spot.id)) &&
              isDragging
            ) {
              hidden = true;
            }

            let style = null;
            if (cursorSpot === spot.id) style = { zIndex: 999 };
            if (hidden) style = { display: "none" };

            return (
              <SpotNode
                cursorOverSpot={cursorSpot === spot.id}
                cursorOverTrash={cursorOverTrash}
                interactive={!KEYS_DOWN.includes(" ") && !isDragging}
                isDragging={isDragging}
                isPanning={isPanning}
                isNetting={isNetting}
                isValidDraggingLocation={isValidDraggingLocation}
                key={spot.id}
                netSelected={netSelected}
                selected={selected}
                setSelected={setSelected}
                setSingleSpot={setSingleSpot}
                setSpots={setSpots}
                singleSpot={singleSpot}
                spot={spot}
                spots={spots}
                style={style}
                trash={trash}
                zoom={zoom}
              />
            );
          })
        }
        <div id="adding-spot-shadow" />
      </Container>
      {
        !isEdit && (
          <Trash
            {...props}
            active={selected.length > 0}
            ref={trash}
            singleSpot={singleSpot}
          />
        )
      }
      <ZoomControls
        {...props}
        isZooming={isZooming}
        layout={layout}
        setIsZooming={setIsZooming}
      />
      <Container
        isZooming={isZooming}
        style={{
          pointerEvents: "none",
          transform: containerTransform,
          width: containerWidth,
        }}
      >
        {
          /**
           * 6. All the spots that are being dragged or moused over
           */
          spots.map(spot => {
            if (
              (!isSpotSingular(spot, props) && !selected.includes(spot.id)) ||
              !isDragging
            )
              return null;
            const offset = getSpotOffset(spot, props);
            return (
              <SpotNode
                cursorOverSpot={false}
                cursorOverTrash={cursorOverTrash}
                interactive={!KEYS_DOWN.includes(" ") && !isDragging}
                isDragging={isDragging}
                isNetting={isNetting}
                isPanning={isPanning}
                isValidDraggingLocation={isValidDraggingLocation}
                key={spot.id}
                netSelected={netSelected}
                selected={selected}
                setSelected={setSelected}
                setSingleSpot={setSingleSpot}
                setSpots={setSpots}
                singleSpot={singleSpot}
                spot={spot}
                spots={spots}
                style={{
                  transform: `translateX(${offset.x}px) translateY(${offset.y}px)`,
                }}
                trash={trash}
                zoom={zoom}
              />
            );
          })
        }
      </Container>
    </>
  );
};
