import type { AppProps } from "../types/AppProps";
import type Point from "../types/Point";
import canvasCenter from "../utils/canvasCenter";

/**
 * Convert a point in screen space to canvas space.
 * @param point
 * @param props
 */
export default function screenToCanvas(point: Point, props: AppProps): Point {
  const { origin, zoom } = props;

  const center = canvasCenter();

  const canvasPoint = { ...point };

  canvasPoint.x += zoom * (center.x - origin.x) - center.x;
  canvasPoint.y += zoom * (center.y - origin.y) - center.y;

  canvasPoint.x /= zoom;
  canvasPoint.y /= zoom;

  return canvasPoint;
}
