import type { AppProps, SetAppProps } from "../types/AppProps";
import eventWithinSpot from "../utils/eventWithinSpot";
import abstractEnd from "./abstractEnd";

export default function onMouseUp(
  props: AppProps &
    SetAppProps & {
      reset: (hard?: boolean) => void;
      trash: React.RefObject<HTMLButtonElement>;
    }
) {
  return function onMouseUp(e: React.MouseEvent<HTMLElement>) {
    const maybeSpotId = eventWithinSpot(e);
    abstractEnd(props, maybeSpotId);
  };
}
