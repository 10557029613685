import Spot from "../types/Spot";

export function getSpotById(spots: Spot[], id: string): Spot {
  if (!id) return null;
  return spots.find(s => s.id === id);
}

export function getSpotsById(spots: Spot[], ids: string[]): Spot[] {
  return spots.filter(s => ids.includes(s.id));
}
