import * as React from "react";
import styled from "styled-components";
import withPortal from "../utils/withPortal";
import Fill from "./Fill";

const gridGray = "rgba(238, 238, 238, 0.3)";

const StyledGrid = styled(Fill)`
  background-image: linear-gradient(90deg, ${gridGray} 50%, transparent 50%),
    linear-gradient(${gridGray} 50%, transparent 50%);
`;

/**
 * This gets rendered into an element *behind* (i.e. in order, before)
 * the rest of the <App> so that it always appears behind everything visually.
 */
const GridPattern = withPortal(
  ({ children, ...props }: React.HTMLAttributes<HTMLElement>) => (
    <StyledGrid {...props} />
  ),
  "grid-pattern"
);

export default GridPattern;
