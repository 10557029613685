import { AppProps } from "../types/AppProps";
import Spot, { SPOT_HEIGHT, SPOT_UNIT, SPOT_WIDTH } from "../types/Spot";
import alignToGrid from "./alignToGrid";
import bbIntersect from "./bbIntersect";
import { getSpotById, getSpotsById } from "./getSpotById";
import type BoundingBox from "../types/BoundingBox";

/**
 * Create a buffer around a Spot so that, when dragging, no other
 * spot can get within 1 unit of it
 */
export const spotWithBuffer = (spot: Spot): BoundingBox => {
  return {
    x: spot.x,
    y: spot.y,
    width: SPOT_WIDTH + 2 * SPOT_UNIT,
    height: SPOT_HEIGHT + 2 * SPOT_UNIT,
  };
};

export default function determineValidDraggingLocation({
  cursor,
  cursorDown,
  selected,
  singleSpot,
  spots,
  zoom,
}: AppProps): boolean {
  const dx = (cursor.x - cursorDown.x) / zoom;
  const dy = (cursor.y - cursorDown.y) / zoom;

  const selectedSpots = getSpotsById(spots, selected);
  if (selectedSpots.length > 0) {
    const unSelectedSpots = spots.filter(s => !selected.includes(s.id));
    // to be a valid location, EVERY selected spot
    // must NOT intersect with one or more of the spots
    return selectedSpots.every(sel => {
      const selOffsetXY = alignToGrid({ x: sel.x + dx, y: sel.y + dy });
      const selWithOffset = {
        ...selOffsetXY,
        height: SPOT_HEIGHT,
        width: SPOT_WIDTH,
      };
      return !unSelectedSpots.some(spot => {
        return bbIntersect(spotWithBuffer(spot), selWithOffset);
      });
    });
  } else if (singleSpot !== null) {
    const theSingleSpot = getSpotById(spots, singleSpot);
    const xy = alignToGrid({
      x: theSingleSpot.x + dx,
      y: theSingleSpot.y + dy,
    });
    const singleWithOffset = {
      ...xy,
      height: SPOT_HEIGHT,
      width: SPOT_WIDTH,
    };
    // to be a valid location, there must NOT be
    // an intersection between one or more of the spots
    // and the singleSpot being dragged
    return !spots.some(spot => {
      if (spot.id === singleSpot) return false;
      return bbIntersect(spotWithBuffer(spot), singleWithOffset);
    });
  }
}
