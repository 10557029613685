import Spot from "../types/Spot";
import { SPOT_COUNT_MAX } from "../constants";

export default function autoSpotNumber(spots: Spot[]): string {
  let name = "1";
  let maxSpotNumber = -Infinity;
  let hitMax = false;

  spots.forEach(spot => {
    if (+spot.name === SPOT_COUNT_MAX) hitMax = true;

    if (+spot.name > maxSpotNumber && +spot.name < SPOT_COUNT_MAX) {
      maxSpotNumber = +spot.name;
      name = (+spot.name + 1).toString();
    }
  });

  if (hitMax) {
    const sortedNames = spots
      .filter(s => !isNaN(+s.name))
      .map(s => +s.name)
      .sort((a, b) => a - b);
    let i = 0;
    do {
      name = (sortedNames[i] + 1).toString();
      i++;
    } while (sortedNames.includes(+name));
    if (+name > SPOT_COUNT_MAX) name = (sortedNames[0] - 1).toString();
  }

  return name;
}
