import styled, { css } from "styled-components";
import { Z_INDICES } from "../constants";
import { SPOT_HEIGHT, SPOT_WIDTH } from "../types/Spot";

const SpotShadow = styled.div<{ invalid: boolean }>`
  background-color: var(--color-primary-extralight);
  border: 2px dashed var(--color-primary);
  border-radius: 3px;
  height: ${SPOT_HEIGHT}px;
  pointer-events: none;
  position: absolute;
  width: ${SPOT_WIDTH}px;
  z-index: ${Z_INDICES.SPOT_SHADOW};

  ${props =>
    props.invalid &&
    css`
      background-color: var(--color-UInegative-extralight);
      border-color: var(--color-UInegative-dark);
    `}
`;

export default SpotShadow;
