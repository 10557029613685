import {
  calculateSidePanelWidth,
  sidePanelWidth,
} from "../components/SidePanel";
import Point from "../types/Point";

export default function canvasCenter(): Point {
  return {
    x: (window.innerWidth - calculateSidePanelWidth()) / 2,
    y: window.innerHeight / 2,
  };
}
