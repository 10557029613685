import type { AppProps, SetAppProps } from "../types/AppProps";
import eventWithinSpot from "../utils/eventWithinSpot";
import abstractEnd from "./abstractEnd";

export default function onTouchEnd(
  props: AppProps &
    SetAppProps & {
      reset: (hard?: boolean) => void;
      trash: React.RefObject<HTMLButtonElement>;
    }
) {
  return function onTouchEnd(e: React.TouchEvent<HTMLElement>) {
    const maybeSpotId = eventWithinSpot(e);
    abstractEnd(props, maybeSpotId);
  };
}
