import * as React from "react";
import queryString from "query-string";
import { checkAuthentication, isSandbox, login, sandbox } from "../auth";
import urlParser from "./urlParser";
import { saveClassroomID } from "./store";
import { useXProps } from "../hooks/useXprops";

/**
 */
export default function withAuth<Props>(
  Component: (props: Props) => JSX.Element
) {
  return (props: Props) => {
    const [redirectUri, setRedirectUri] = React.useState<string>();
    const { search } = props as unknown as { search: string }; 
    const params = queryString.parse(search || window.location.search);
    if (params.sandbox) sandbox();

    const [authenticated, setAuthenticated] = React.useState(null);

    // If there's a classroom in the path
    // store it to localstorage and pass it to the App component's props
    const { classroomId: xpropClassroomId, initialRoute } = useXProps();
    const classroomId = (xpropClassroomId || urlParser.getClassroom(initialRoute));
    if (classroomId) saveClassroomID(classroomId);

    React.useEffect(() => {
      if (isSandbox()) return setAuthenticated(true);
      (async () => {
        const { isAuthenticated, redirectUri } = await checkAuthentication();
        
        setRedirectUri(redirectUri);
        setAuthenticated(isAuthenticated);
      })();
    }, []);

    // indeterminate: return nothing
    if (authenticated === null) {
      return null;
    }
    // if not authenticated, render a log in button
    else if (!authenticated) {
      login();
      return null;
    }
    // if authenticated, render the wrapped component
    else {
      return <Component {...{ ...props, classroomId, redirectUri }} />;
    }
  };
}
