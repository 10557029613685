import store from "store";
import type LayoutData from "../types/LayoutData";
import type Spot from "../types/Spot";
import type Point from "../types/Point";
import { percentDecode } from "./percentEncodingHack";

const layoutKey = (name: string) => "mariana-layout-" + name;
const classroomKey = "mariana-classroom";

/**
 * Given the current layout's name, retrieve layout data
 * from localStorage.
 * @param {string} name
 */
export function getLayoutData(name: string): LayoutData {
  const trueKey = layoutKey(percentDecode(name));
  return store.get(trueKey, null);
}

/**
 * Save spot data to localStorage, associating it with the layout's
 * name so that we can retrieve it later.
 * @param {string} name
 */
export function saveLayoutData(
  name: string,
  data: {
    origin?: Point;
    spots?: Spot[];
    zoom?: number;
  }
): void {
  if (name === "") return;
  const layoutData = getLayoutData(name);
  if (layoutData !== null) {
    store.set(layoutKey(name), Object.assign(layoutData, data));
  } else {
    store.set(layoutKey(name), data);
  }
}

/**
 * Rename a layout without making any changes to its data otherwise.
 * @param {string} oldName
 * @param {string} newName
 */
export function renameLayout(oldName: string, newName: string): void {
  // get the spots currently associated with the layout
  const data = getLayoutData(oldName);
  // delete the old data
  store.remove(layoutKey(oldName));
  // associate the spot data with the new name
  data.name = newName;
  saveLayoutData(newName, data);
}

export function saveClassroomID(id: string) {
  store.set(classroomKey, id);
}

export function getClassroomID(): string {
  return store.get(classroomKey, null);
}
