import { Point } from "electron";

export default function touchesToCursor(
  touches: React.TouchList | TouchList
): Point {
  if (touches.length === 0) return null;

  const cursor: Point = Array.from(touches).reduce(
    (p, touch) => {
      return {
        x: p.x + touch.clientX,
        y: p.y + touch.clientY,
      };
    },
    { x: 0, y: 0 }
  );

  cursor.x /= touches.length;
  cursor.y /= touches.length;

  return cursor;
}
